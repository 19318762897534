import styled from 'styled-components';

export const GiftNote = styled.div`
    position: relative;
    margin-top: 25px;
`;

export const Note = styled.p` 
    font-size: 20px;
    font-weight: 400; 
    line-height: 21px;

    margin-bottom: 25px;

    > span{
        font-weight: 800;
        color: ${props => props.theme.colors.primary}
    }
`;

export const GiftButton = styled.button<{ fill?: string }>`
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    padding: 10px;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    background-color: ${props => props.fill === "true" ? props.theme.colors.primary : 'white'};

    color: ${props => props.fill ? 'white' : props.theme.colors.primary};

    > a{
        width: 100%;
        display: block;
        text-align: center;

        color: inherit;
    }
`;