import React, { SyntheticEvent, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../../../store';
import { IClassified } from "../../../store/containers/classified/types"

import { ContainerList, Card, CardContainer, CardImage, CardCategory, CardTitle, CardWrapper, CardValor, CardButton } from './style';

import TypeProducts from './componets/TypeProducts';
import ClassifiedSearch from '../components/Search';

import defaultImage from '../../../assets/images/default.jpg'

interface StateProps {
    products: IClassified[]
}

type Props = StateProps

const Products: React.FC<Props> = ({ products }: Props) => {
    const [tipoUso, setTipoUso] = useState(0);
    const [query, setQuery] = useState("");

    function imageError(ev: SyntheticEvent<HTMLImageElement, Event>) {
        // eslint-disable-next-line no-param-reassign
        ev.currentTarget.src = defaultImage
    }

    return (
        <>
            <ClassifiedSearch setQuery={setQuery} />
            <TypeProducts
                usedProducts={products.filter(product => product.TipoUso === 0).length}
                newProducts={products.filter(product => product.TipoUso === 1).length}
                setTipoUso={setTipoUso}
            />
            <ContainerList>
                {
                    products.filter(product => product.TipoUso === tipoUso && product.Titulo.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0).map((product: IClassified) => (
                        <Link key={product.CodigoClassificadoItem} to={{ pathname: `classified/show/${product.CodigoClassificadoItem}` }}>
                            <Card >
                                <CardContainer>
                                    <CardWrapper>
                                        <CardCategory>{product.Categoria}</CardCategory>
                                        <CardTitle>{product.Titulo}</CardTitle>
                                    </CardWrapper>
                                    <CardWrapper>
                                        <CardValor>R$ {product.Valor.toFixed(2).toString().replace(".", ",")}</CardValor>
                                        <CardButton>
                                            <span>Detalhes</span>
                                        </CardButton>
                                    </CardWrapper>
                                </CardContainer>

                                <CardImage>
                                    <img onError={imageError} src={product.Imagem1} alt="" />
                                </CardImage>
                            </Card>
                        </Link>
                    ))
                }
            </ContainerList>
        </>
    )
}
const mapStateToProps = (state: ApplicationState) => ({
    products: state.classifieds.products
})

export default connect(mapStateToProps)(Products);