import { useMemo } from 'react'
import { Card, Container, Title } from './styles'

const HubPage: React.FC = () => {
    const clientId = '636851307236352794'
    const clientSecret = '637819227270000000'
    const redirectUri = 'http://localhost:3000/#/'
    const platform = 'ANDROID'
    const colCodigo = '573909'
    const ngrok = 'simplificaci.ngrok.io'

    //localhost:3000/#/birthlife#simplificaci.ngrok.io;ANDROID;birthlife|ANDROID:637819227270000000|636851307236352794|3036|573909
    //localhost:3000/#/birthwork#simplificaci.ngrok.io;ANDROID;birthwork|ANDROID:637819227270000000|636851307236352794|3046|573909
    //localhost:3000/#/birthlife#simplificaci.ngrok.io;ANDROID;birthwork|ANDROID:637819227270000000|636851307236352794|3046|573909

    const services = useMemo(
        () => [
            {
                name: 'birthlife',
                code: '3036',
            },
            {
                name: 'birthwork',
                code: '3046',
            },
            {
                name: 'settings',
                code: '0',
            },
            {
                name: 'classified',
                code: '2929',
            },
        ],
        []
    )

    return (
        <Container>
            {services.map((service) => (
                <Card key={service.name}>
                    <Title
                        key={service.name}
                        href={`${redirectUri}${service.name}#${ngrok};${platform};${service.name}|${platform}:${clientSecret}|${clientId}|${service.code}|${colCodigo}`}>
                        {service.name.toLocaleUpperCase()}
                    </Title>
                </Card>
            ))}
        </Container>
    )
}

export default HubPage
