import styled from 'styled-components'

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 16px;
`

export const PhotoContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
    width: 100%;
`

export const UserPhoto = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 50%;
`

export const UserInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
`

export const ColName = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.text};
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    width: 100%;
`

export const ColCargo = styled.p`
    font-size: 12px;
    color: ${(props) => props.theme.colors.textMuted};
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;

    text-overflow: ellipsis;
`

export const Day = styled.p`
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    color: ${(props) => props.theme.colors.background};
    font-weight: 600;
    font-size: 12px;
    background-color: ${(props) => props.theme.colors.primary};
    text-align: center;
    padding: 4px 8px;
    border-radius: 6px;
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
`

export const MonthDay = styled.p`
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    color: ${(props) => props.theme.colors.background};
    font-weight: 600;
    font-size: 12px;
    background-color: ${(props) => props.theme.colors.primary};
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
`
