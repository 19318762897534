import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 0 25px;
`;

export const Divider = styled.hr`
    margin-bottom: 20px;
    border:none;
    border-bottom: 1px solid #EBEBEB;
`

export const Infos = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const InfoLabel = styled.div`
    padding: 5px 10px;
    border-radius: 5px;
    width: fit-content;

    font-size: 12px;
    font-weight: 700;
`

export const InfoType = styled(InfoLabel) <{ tipoUso: number }>`
    background-color: ${props => props.theme.classifiedCategories.tipoUso.find(tipo => tipo.key === props.tipoUso)?.background};
    color: ${props => props.theme.classifiedCategories.tipoUso.find(tipo => tipo.key === props.tipoUso)?.color};
`

export const InfoDate = styled.p`
    font-size: 12px;
    font-weight: 400;
`

export const Header = styled.div`
    position: relative;
    padding: 15px 0;
`

export const Title = styled.h1`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
`

export const Price = styled.h2`
    font-size: 22px;
    font-weight: 700;
    color: ${props => props.theme.colors.primary};
`

export const Description = styled.div`
    position: relative;
    margin: 20px 0;

    h3 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
    }
`

export const CarouselPlaceHolder = styled.div`
    position: relative;
     > img {
        width: 100%;
    }
`

export const Labels = styled.div`
    position: relative;
    margin-bottom: 20px;
`

export const InfoTrade = styled(InfoLabel) <{ AceitaTroca: boolean }>`
    background-color: ${props => props.theme.classifiedCategories.aceitaTroca.background};
    color: ${props => props.theme.classifiedCategories.aceitaTroca.color};
`