import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, useLocation } from 'react-router'
import { Dispatch } from 'redux'
import Loader from '../../components/Loader'
import { ApplicationState } from '../../store'
import SettingsThunk from '../../store/containers/settings/thunk'
import { ISettingsSetup } from '../../store/containers/settings/types'

interface StateProps {
    setup: ISettingsSetup
    loading: boolean
    error: boolean
    errorMessage: string
}

interface DispatchProps {
    loadSetup: (hash: string) => void
}

type Props = StateProps & DispatchProps

export const Settings: React.FC<Props> = ({
    setup,
    loading,
    error,
    errorMessage,
    loadSetup,
}) => {
    const { hash } = useLocation()
    useEffect(() => {
        loadSetup(hash)
    }, [])

    return (
        <Loader
            loading={setup.isLoaded}
            error={error}
            errorMessage={errorMessage}>
            <Redirect to={{ pathname: '/settings/screen' }} />
        </Loader>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    setup: state.settings.setup,
    loading: state.settings.loading,
    error: state.settings.error,
    errorMessage: state.settings.errorMessage,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadSetup: (hash: string) => dispatch(SettingsThunk.loadSetup(hash) as any),
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
