const messages = {
    pt: {
        translations: {
            general: {
                goBack: 'Voltar',
                day: 'Dia',
                week: 'Semana',
                month: 'Mês',
                years: 'Anos',
                year: 'Ano',
                since: 'Desde',
            },
            birthdays: {
                notFound: 'Não há aniversariantes neste periodo',
            },
            birthwork: {
                notFound: 'Nenhuma celebração neste período',
            },
            documents: {
                see: 'Ver',
            },
            training: {
                todo: 'A Realizar',
                done: 'Realizados',
                'your-progress': 'Seu Progresso',
            },
            terms: {
                pendings: 'Pendentes',
                accepted: 'Aceitos',
            },
            gifts: {
                'for-company': 'Para uso exclusivo da empresa:',
                'pin-note': 'Informe o PIN de acesso para confirmar a entrega.',
                'last-rescue': 'Data do último resgate:',
                'no-last-rescue': 'Nenhum resgate solicitado previamente',
            },
            settings: {
                profile: 'Perfil',
                'social-name': 'Nome social',
                'not-specified': 'Não especificado',
                genero: 'Gênero',
                'genero-list': {
                    masculine: 'Masculino',
                    feminine: 'Feminino',
                    'non-binary': 'Não-binário',
                    other: 'Outro',
                },
                pronome: 'Pronomes',
                accessibility: 'Acessibilidade',
                'dark-mode': 'Modo escuro',

                'color-filter': 'Filtro de cores',
                'color-filter-protoanopia': 'Protanopia',
                'color-filter-deuteranopia': 'Deuteranopia',
                'color-filter-tritanopia': 'Tritanopia',

                contraste: 'Contraste',
                'contraste-low': 'Baixo',
                'contraste-normal': 'Normal',
                'contraste-high': 'Alto',

                'font-size': 'Tamanho da fonte',
                'font-size-small': 'Pequeno',
                'font-size-normal': 'Normal',
                'font-size-large': 'Grande',

                libras: 'Libras',
                'text-to-speech': 'Leitura de texto',

                security: 'Segurança',
                'app-activation': 'Ativação do Aplicativo',
                'use-biometry': 'Usar biometria',

                notifications: 'Avisos e Notificações',
                birthdays: 'Aniversários',
                'allow-birthdays': 'Permitir Divulgações',
            },
        },
    },
}

export { messages }
