import styled from 'styled-components';

export const GiftContainer = styled.div`
    position: relative;
    margin-bottom: 25px;
`;

export const GiftListHeader = styled.h2`
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 25px;
`

export const GiftListWrapper = styled.ul`
    list-style: none;
`

export const GiftItem = styled.li<{ list?: string }>`
    margin-bottom:  ${props => props.list === "true" ? '15px' : '0'};
    font-size: 20px;
    font-weight: 400;

    >red{
        border: 1px solid #800000;
        border-radius: 5px;
        padding: 4px 3px 4px 4px;
        background-color: #800000;
        color: white;
    }
    >blue{
        border: 1px solid #000080;
        border-radius: 5px;
        padding: 2px 4px;
        background: #000080;
        color: white;
    }
`

export const GiftMessage = styled.p`
    font-size: 20px;
`;