import styled from 'styled-components';

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 500;
`;

export const Dialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: none;

  min-width: 270px;
  width: fit-content;
  max-width: 90%;

  background-color: white;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  padding: 35px 30px;
`

export const DialogCloseButton = styled.button` 
  position: absolute;
  top: -2px;
  left: -2px;
  border: none;
  border-radius: 20px 0px 12px;
  background-color: ${props => props.theme.colors.primary};
  padding: 10px 15px;

  &:before{
    content: "X";
    font-size: 26px;
    line-height: 26px;
    color: white;
  }
  :hover {
    cursor: pointer;
  }
`

export const DialogIcon = styled.img``

export const DialogTitle = styled.h2`
  font-size: 20px;
  font-weight: 400;
 
  > span {
    color: ${props => props.theme.colors.primary};
    font-weight: 800;
  }
`

export const DialogContent = styled.div`
  position: relative;
`

export const DialogContentText = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: ${props => props.theme.colors.text};

  text-align: center;

  margin-bottom: 15px;
`

export const DialogActions = styled.div`
  text-align: center;
`

export const Button = styled.button`
  margin: auto;
  width: 90%;
  font-size: 20px;
  font-weight: 700;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px
`