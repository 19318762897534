import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogCloseButton,
    Backdrop
} from './style';

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
    modalContent: JSX.Element;
    headerText: string;
}
export const Modal: React.FC<ModalProps> = ({
    isShown,
    hide,
    modalContent,
    headerText,
}) => {
    const onKeyboard = (event: KeyboardEvent) => {
        if (event.keyCode === 27 && isShown) {
            hide()
        }
    }

    useEffect(() => {
        document.body.style.overflow = isShown ? 'hidden' : 'unset'
        document.addEventListener('keydown', onKeyboard, false);
        return () => {
            document.removeEventListener('keydown', onKeyboard, false);
        }
    }, [isShown])

    const modal = (
        <>
            <Backdrop onClick={hide} />
            <Dialog arial-modal aria-labelledby={headerText} tabIndex={-1} role="dialog">
                <DialogCloseButton onClick={hide} />
                <DialogTitle>{headerText}</DialogTitle>
                <DialogContent>
                    {modalContent}
                </DialogContent>
            </Dialog>
        </>
    );
    return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};