import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
`;

export const LoadingModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 700;
    width: inherit;
    outline: none;

    width: fit-content;
    max-width: 90%;

    background-color: white;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    padding: 25px 30px;
`

export const Spinning = styled.img`
    width: 106px;
    height: 106px;
    filter: drop-shadow(0 4px rgba(0, 0, 0, 0.1)); 
`

export const Text = styled.h2`
    font-size:20px;
    font-weight: 300;
    font-style: italic;
`

export const DarkScreen = styled.div`
    position: fixed;
    z-index: 100;
    background-color: #333;
    opacity: 0.3;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
`