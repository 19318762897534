import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ThemeProvider } from 'styled-components'
import App from './App'

import './index.css'
import simplifica from './styles/themes/simplifica'

Sentry.init({
    dsn: 'https://091ffe85bd1d4597b658b50556491e2a@o203485.ingest.sentry.io/5974375',
    integrations: [new Integrations.BrowserTracing()],
    normalizeDepth: 10,
    tracesSampleRate: 1.0,
})

const drop = document.getElementById('drop')

setTimeout(() => {
    drop?.classList.add('hide')
    setTimeout(() => {
        ReactDOM.render(
            <ThemeProvider theme={simplifica}>
                <App />
            </ThemeProvider>,
            document.getElementById('root')
        )
    }, 800)
}, 800)
