import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    display: -webkit-box;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colors.background};
    padding: 15px 0;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

export const Text = styled.input`
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
`

export const Divider = styled.p`
    height: 30px;
    width: 1px;
    background-color: #DBDBDB;
    margin: 0 25px;
`