/* eslint-disable  */
import React, { SyntheticEvent } from "react";
import { Link } from "react-router-dom";
import { ITerm } from "../../../../../store/containers/terms/types";
import defaultImage from "../../../../../assets/images/default.jpg";

import { Card, CardImage, CardBody, CardTitle, CardDepartment, CardButton, termButtons } from "./style";

type Props = {
  term: ITerm;
};

const Item: React.FC<Props> = ({ term }: Props) => {
  function imageError(ev: SyntheticEvent<HTMLImageElement, Event>) {
    // eslint-disable-next-line no-param-reassign
    ev.currentTarget.src = defaultImage;
  }

  return (
    <Card status={term.status}>
      <Link to={{ pathname: `/terms/show/${term.codigo}` }}>
        <CardImage onError={imageError} src={term.imagem} />
        <CardBody>
          <CardTitle>{term.titulo}</CardTitle>
          <CardDepartment>{term.subtitulo}</CardDepartment>
          <CardButton status={term.status}>
            <span>
              <img src={termButtons.find((button) => button.key === term.status)?.icon} alt="" className="" />
              {termButtons.find((button) => button.key === term.status)?.text}
            </span>
          </CardButton>
        </CardBody>
      </Link>
    </Card>
  );
};

export default Item;
