import React from 'react'
import { IGift, IGiftSetup } from '../../../store/containers/gifts/types'
import GiftList from '../components/gift-list'
import GiftNotes from '../components/gift-note'

import { Container, GiftQueueContainer, GiftIcon, Note, Separator, GiftNote, GiftButton } from "./style"

type Props = {
    gifts: IGift,
    giftSetup: IGiftSetup,
    RequestGift: () => void
}

const QueueScreen: React.FC<Props> = ({ gifts, RequestGift, giftSetup }: Props) =>
(
    <Container>
        {gifts?.status !== "received" && gifts?.status &&
            <>
                <GiftQueueContainer flex>
                    <GiftIcon>
                        <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M40.6853 11.1765H33.0461C33.7413 10.2541 34.154 9.10816 34.154 7.86765C34.154 4.82684 31.6757 2.35294 28.6295 2.35294C26.7945 2.35294 25.4414 3.00919 24.3712 4.41816C23.4759 5.59699 22.8212 7.24287 22 9.32243C21.2143 6.27451 20.8672 5.10039 19.9718 3.92157C18.9016 2.5126 17.2857 0 14.1429 0C11.7857 0 8.64286 1.6788 8.64286 6.27451C8.64286 9.32243 10.2587 10.2541 10.9539 11.1765H3.31473C1.48699 11.1765 0 12.6608 0 14.4853V16.6912C0 18.129 0.346019 20 5.35268 19.8103V36.6912C5.35268 38.5157 6.83967 40 8.66741 40C8.66741 40 33.5048 40 35.3326 40C37.1603 40 39.2857 38.6872 39.2857 36.8627V20C43.8014 20 44 18.129 44 16.6912V14.4853C44 12.6608 42.513 11.1765 40.6853 11.1765ZM24.0282 10.2015C25.7208 5.91485 26.3643 5.34195 28.6295 5.34195C29.8571 5.34195 31.4286 6.57574 31.4286 7.84314C31.4286 10.1961 29.3277 10.9804 27.5 10.9804L23.6417 11.1765C23.7771 10.8371 23.9062 10.5104 24.0282 10.2015ZM14.1429 3.13725C15.7143 3.13725 18.0714 4.70588 19.9718 10.2015C20.0804 10.5154 20.2229 10.8371 20.3583 11.1765H16.6315C13.7475 11.1765 11.9917 8.87169 11.7857 7.05882C11.5156 4.68137 12.5714 3.13725 14.1429 3.13725ZM17.5804 36.8627H10.2143H9.13393V20H17.5804V36.8627ZM17.5804 17.2549H3.14286C2.53361 17.2549 2.20982 17.0788 2.20982 16.4706V15.0878C2.20982 14.4797 2.70548 14.1176 3.31473 14.1176H17.5804V17.2549ZM23.5714 36.8627H20.4286V13.3333C20.6601 13.3333 22.3015 13.3333 23.5714 13.3333V36.8627ZM35.5262 35.2941C35.5262 35.9023 35.1807 36.8627 34.5714 36.8627H26.4196V20H35.5262V35.2941ZM41.7902 16.2868C41.7902 16.8949 41.4664 17.2549 40.8571 17.2549H26.4196V14.1176H40.8571C41.4664 14.1176 41.7902 14.4797 41.7902 15.0878V16.2868Z" fill="#7C0A73" />
                            <path d="M40.6853 11.1765H33.0461C33.7413 10.2541 34.154 9.10816 34.154 7.86765C34.154 4.82684 31.6757 2.35294 28.6295 2.35294C26.7945 2.35294 25.4414 3.00919 24.3712 4.41816C23.4759 5.59699 22.8212 7.24287 22 9.32243C21.2143 6.27451 20.8672 5.10039 19.9718 3.92157C18.9016 2.5126 17.2857 0 14.1429 0C11.7857 0 8.64286 1.6788 8.64286 6.27451C8.64286 9.32243 10.2587 10.2541 10.9539 11.1765H3.31473C1.48699 11.1765 0 12.6608 0 14.4853V16.6912C0 18.129 0.346019 20 5.35268 19.8103V36.6912C5.35268 38.5157 6.83967 40 8.66741 40C8.66741 40 33.5048 40 35.3326 40C37.1603 40 39.2857 38.6872 39.2857 36.8627V20C43.8014 20 44 18.129 44 16.6912V14.4853C44 12.6608 42.513 11.1765 40.6853 11.1765ZM24.0282 10.2015C25.7208 5.91485 26.3643 5.34195 28.6295 5.34195C29.8571 5.34195 31.4286 6.57574 31.4286 7.84314C31.4286 10.1961 29.3277 10.9804 27.5 10.9804L23.6417 11.1765C23.7771 10.8371 23.9062 10.5104 24.0282 10.2015ZM14.1429 3.13725C15.7143 3.13725 18.0714 4.70588 19.9718 10.2015C20.0804 10.5154 20.2229 10.8371 20.3583 11.1765H16.6315C13.7475 11.1765 11.9917 8.87169 11.7857 7.05882C11.5156 4.68137 12.5714 3.13725 14.1429 3.13725ZM17.5804 36.8627H10.2143H9.13393V20H17.5804V36.8627ZM17.5804 17.2549H3.14286C2.53361 17.2549 2.20982 17.0788 2.20982 16.4706V15.0878C2.20982 14.4797 2.70548 14.1176 3.31473 14.1176H17.5804V17.2549ZM23.5714 36.8627H20.4286V13.3333C20.6601 13.3333 22.3015 13.3333 23.5714 13.3333V36.8627ZM35.5262 35.2941C35.5262 35.9023 35.1807 36.8627 34.5714 36.8627H26.4196V20H35.5262V35.2941ZM41.7902 16.2868C41.7902 16.8949 41.4664 17.2549 40.8571 17.2549H26.4196V14.1176H40.8571C41.4664 14.1176 41.7902 14.4797 41.7902 15.0878V16.2868Z" fill="#7C0A73" />
                        </svg>
                    </GiftIcon>
                    <Note>
                        {giftSetup.apps_brindes_descricao}
                    </Note>
                </GiftQueueContainer>
                <Separator />
            </>
        }
        <GiftQueueContainer>
            <GiftList gifts={gifts} giftSetup={giftSetup} />
            <GiftNotes gifts={gifts} RequestGift={RequestGift} giftSetup={giftSetup} />
        </GiftQueueContainer>
    </Container>
)

export default QueueScreen