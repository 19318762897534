import styled from 'styled-components'

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: ${(props) => props.theme.colors.primary};
    padding: 8px 16px;
    border-radius: 4px;
    margin: 8px;
`

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-left: 16px;
`

export const Title = styled.h2`
    font-size: 16px;
    font-weight: 800;
    color: #fff;
`

export const Description = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: #fff;
`
