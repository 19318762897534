import React from 'react'
import { SwitchCheckbox, SwitchContainer, SwitchSlider } from './styles'

interface SwitchProps {
    value: boolean
    onChange: (value: boolean) => void
}

const Switch: React.FC<SwitchProps> = ({ value, onChange }: SwitchProps) => (
    <SwitchContainer>
        <SwitchCheckbox
            type='checkbox'
            checked={value}
            onChange={(event) => onChange(event.target.checked)}
        />
        <SwitchSlider />
    </SwitchContainer>
)

export default Switch
