import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 15px;
  margin-top: 30px;
`;

export const TrainingContainer = styled.div`

`

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 700;
`