import React, { useEffect } from 'react'

import BirthdaysItem from '../../../../../components/BirthdayItem'
import BirthdayNotFound from '../../../../../components/BirthdayNotFound'
import BirthworkItem from '../../../../../components/BirthworkItem'
import BirthworkNotFound from '../../../../../components/BirthworkNotFound'
import SimpleLoading from '../../../../../components/SimpleLoading'
import {
    IBirthday,
    TBirthdayType,
} from '../../../../../store/containers/birthdays/types'
import { IBirthwork } from '../../../../../store/containers/birthwork/types'
import { ListContainer, LoaderContainer } from './style'

interface BirthdaysListProps {
    birthdays: Array<IBirthday> | Array<IBirthwork>
    activeTab: number
    cardType: TBirthdayType
}

const List: React.FC<BirthdaysListProps> = (props: BirthdaysListProps) => {
    const { birthdays, activeTab, cardType } = props

    useEffect(() => {
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100)
    }, [activeTab])

    return (
        <>
            {birthdays && birthdays.length > 0 && (
                <>
                    {birthdays[0] && birthdays[0].isPlaceholder ? (
                        <LoaderContainer>
                            <SimpleLoading />
                        </LoaderContainer>
                    ) : (
                        <ListContainer cardType={cardType}>
                            {birthdays.length > 0 &&
                                birthdays.map((birthday) =>
                                    cardType === 'birthdays' ? (
                                        <BirthdaysItem
                                            birthday={birthday}
                                            activeTab={activeTab}
                                            key={birthday.colCodigo}
                                        />
                                    ) : (
                                        <BirthworkItem
                                            birthday={birthday as IBirthwork}
                                            activeTab={activeTab}
                                            key={birthday.colCodigo}
                                        />
                                    )
                                )}
                        </ListContainer>
                    )}
                </>
            )}
            {(!birthdays || birthdays.length === 0) &&
                cardType === 'birthdays' && <BirthdayNotFound />}
            {(!birthdays || birthdays.length === 0) &&
                cardType === 'birthwork' && <BirthworkNotFound />}
        </>
    )
}

export default List
