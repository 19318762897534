import * as Sentry from "@sentry/react"
import { AxiosError, AxiosResponse } from "axios"
import { Dispatch } from "redux"
import { asyncLocalStorage } from "../../../auth/auth"
import * as GiftActions from "./actions"
import { api } from "../../../services/api"
import simplifica from "../../../styles/themes/simplifica"

const GiftsThunk = {
    loadSetup: (hash: string) => (dispatch: Dispatch) => {
        api.post("/brindes/setup", { hash })
            .then((response: AxiosResponse) => {
                if (response.data?.state) {
                    GiftsThunk.loadTheme(response.data.data)
                    asyncLocalStorage.setItem("@services_header", JSON.parse(response.data.data)[0].EsconderHeader)
                        .then(() => {
                            dispatch(GiftActions.loadGiftSetup(response.data.locales))
                        })
                } else {
                    Sentry.captureException("SETUP - Erro no Carregamento");
                    dispatch(GiftActions.loadGiftFailure("SETUP - Erro no Carregamento"))
                }
            })
            .catch((error: AxiosError) => {
                Sentry.captureException(error);
                dispatch(GiftActions.loadGiftFailure(`SETUP - ${error.message}`))
            })
    },

    loadTheme: (data: any) => {
        const { TituloModulo, CorPrimaria, CorSecundaria } = JSON.parse(data)[0]
        asyncLocalStorage.setItem("@services_page_title", TituloModulo)
            .then(() => {
                simplifica.colors.primary = CorPrimaria
                simplifica.colors.secondary = CorSecundaria

                simplifica.trainingButtons[0].color = CorPrimaria
                simplifica.trainingButtons[1].color = CorPrimaria

                simplifica.classifiedCategories.tipoUso[0].background = CorSecundaria
            })
    },

    loadGifts: () => (dispatch: Dispatch) => {
        api.get("/brindes/carregar")
            .then((response: AxiosResponse) => {
                if (response.data?.state) {
                    dispatch(GiftActions.loadGiftSuccess(JSON.parse(response.data.data)[0]))
                } else {
                    Sentry.captureException("LoadGifts - Erro no Carregamento");
                    dispatch(GiftActions.loadGiftFailure("LoadGifts - Erro no Carregamento"))
                }
            })
            .catch((error: AxiosError) => {
                Sentry.captureException(error);
                dispatch(GiftActions.loadGiftFailure(`LoadGifts - ${error.message}`))
            })
    },

    setReceived: (history: any, pin?: string, setPinScreen?: any) => (dispatch: Dispatch) => {
        if (pin) dispatch(GiftActions.receivedGiftRequest())

        api.post("/brindes/recebido", { pin })
            .then((response: AxiosResponse) => {
                if (response.data?.state) {
                    const { Status } = JSON.parse(response.data.data)[0]

                    if (pin && Status === "need_pin") {
                        dispatch(GiftActions.receivedGiftFailure("PIN Incorreto."))
                    }
                    if (Status === "received") {
                        dispatch(GiftActions.receivedGiftSuccess())
                        GiftsThunk.loadGifts()(dispatch)
                        setTimeout(() => {
                            setPinScreen(false)
                            history.go("/brindes")
                        }, 1000)
                    }

                    dispatch(GiftActions.setPIN(Status))
                }
            })
    }
}

export default GiftsThunk