import React from 'react'
import { connect } from 'react-redux'

import { ApplicationState } from '../../store'
import {
    IBirthdaySetup,
    IBirthdaysItemProps,
} from '../../store/containers/birthdays/types'
import {
    CardContainer,
    ColCargo,
    ColName,
    Day,
    MonthDay,
    PhotoContainer,
    UserInfoWrapper,
    UserPhoto,
} from './style'

import DefaultUser from '../../assets/images/default-user-image.png'

interface StateProps {
    setup: IBirthdaySetup
}

type Props = StateProps & IBirthdaysItemProps

const BirthdaysCard: React.FC<Props> = (props: Props) => {
    const { birthday, activeTab, setup } = props

    return (
        <CardContainer key={birthday.colCodigo}>
            <PhotoContainer>
                {setup.ShowPhoto && (
                    <UserPhoto
                        src={birthday.colPhoto}
                        alt={birthday.colNome}
                        onError={(e) => {
                            e.currentTarget.src = DefaultUser
                        }}
                    />
                )}
                {activeTab === 2 && <MonthDay>{birthday.diaMes}</MonthDay>}
                {activeTab === 1 && <Day>{birthday.diaSemana}</Day>}
            </PhotoContainer>
            <UserInfoWrapper>
                <ColName>{birthday.colNome}</ColName>
                {setup.ShowCargo && <ColCargo>{birthday.colCargo}</ColCargo>}
            </UserInfoWrapper>
        </CardContainer>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    setup: state.birthdays.setup,
})

export default connect(mapStateToProps)(BirthdaysCard)
