import React, { useEffect, useState } from 'react'
import { IGift, IGiftSetup } from '../../../../store/containers/gifts/types'
import { GiftContainer, GiftListHeader, GiftItem, GiftListWrapper, GiftMessage } from './style'

type Props = {
    gifts: IGift
    giftSetup: IGiftSetup
}

const GiftList: React.FC<Props> = ({ gifts, giftSetup }: Props) => {

    const [list, setList] = useState<string[]>()

    useEffect(() => {
        setList(gifts?.descritivo?.split("|"))
    }, [gifts])

    const listRender = () => {
        if (gifts?.status !== "received" && gifts) {
            return (
                <GiftListWrapper>
                    {
                        list?.map((gift: string, index) => (
                            <GiftItem list="true" key={index} dangerouslySetInnerHTML={{ __html: gift }} />
                        ))
                    }
                </GiftListWrapper>
            )
        }
        return (
            <GiftMessage>
                {giftSetup.apps_brindes_semitens}
            </GiftMessage>
        )
    }

    return (
        <GiftContainer>
            <GiftListHeader>
                {giftSetup.apps_brindes_titulo}
            </GiftListHeader>
            {listRender()}
        </GiftContainer>
    )
}

export default GiftList