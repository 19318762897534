import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Redirect } from 'react-router'
import { ApplicationState } from '../../../store'
import TrainingThunk from '../../../store/containers/training/thunk'
import { ITraining } from '../../../store/containers/training/types'

import Progress from './components/Progress'
import Item from './components/Item'
import { Container, TrainingContainer, Title } from './style'
import TrainingCardSkeleton from './components/Item/trainingCardSkeleton'
import { i18n } from '../../../translate/i18n'

interface StateProps {
    trainings: ITraining[]
    loading: boolean
    error: boolean
}

interface DispatchProps {
    loadTrainings(): void
    loadProgress(trainingsDone: number, trainingsTotal: number): void
}

type Props = StateProps & DispatchProps

const TrainingList: React.FC<Props> = (props: Props) => {
    const [todo, setTodo] = useState<ITraining[]>([])
    const [done, setDone] = useState<ITraining[]>([])

    useEffect(() => {
        const { loadTrainings } = props
        loadTrainings()
    }, [])

    const { loading, error, trainings } = props

    useEffect(() => {
        setTodo(trainings.filter((training) => training.status === 0))
        setDone(trainings.filter((training) => training.status === 1))
    }, [trainings])

    useEffect(() => {
        const { loadProgress } = props
        loadProgress(done.length, todo.length + done.length)
    }, [todo, done])

    const groupSkeletonCards = useCallback((numCards: number) => {
        const skeletonCards: Array<JSX.Element> = []

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < numCards; i++) {
            skeletonCards.push(<TrainingCardSkeleton key={i} />)
        }

        return <>{skeletonCards.map((s) => s)}</>
    }, [])

    const todoTrainings = () => (
        <>
            <TrainingContainer>
                <Title>{i18n.t('trainings.todo')}</Title>
                {todo.map((training: ITraining, key) => (
                    <Item key={key} training={training} />
                ))}
            </TrainingContainer>
        </>
    )

    const doneTrainings = () => (
        <>
            <TrainingContainer>
                <Title>{i18n.t('trainings.done')}</Title>
                {done.map((training: ITraining, key) => (
                    <Item key={key} training={training} />
                ))}
            </TrainingContainer>
        </>
    )

    return (
        <>
            {!error ? (
                <>
                    <Progress />
                    <Container>
                        {loading ? (
                            groupSkeletonCards(5)
                        ) : (
                            <>
                                {todo.length > 0 && todoTrainings()}

                                {done.length > 0 && doneTrainings()}
                            </>
                        )}
                    </Container>
                </>
            ) : (
                <Redirect to={{ pathname: '/error' }} />
            )}
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    trainings: state.trainings.trainings,
    loading: state.trainings.loading,
    error: state.trainings.error,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadTrainings: () => dispatch(TrainingThunk.loadTrainings() as any),
    loadProgress: (actualProgress: number, finalProgress: number) =>
        dispatch({
            type: '@training/LOAD_TRAINING_PROGRESS',
            payload: { progress: { actualProgress, finalProgress } },
        }),
})

export default connect(mapStateToProps, mapDispatchToProps)(TrainingList)
