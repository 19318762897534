import React, { useCallback, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { connect } from 'react-redux'
import { Redirect, useLocation } from 'react-router'
import { useParams } from 'react-router-dom'
import { Dispatch } from 'redux'
import { ApplicationState } from '../../../store'
import DocumentsThunk from '../../../store/containers/documents/thunk'
import { IDocumentFile } from '../../../store/containers/documents/types'

import iconEnter from '../../../assets/images/enter.svg'
import { i18n } from '../../../translate/i18n'
import { Card, CardButton, CardTitle, Container, Subtitle } from './style'

interface StateProps {
    files: IDocumentFile[]
    loading: boolean
    error: boolean
    errorMessage: string
}

interface DispatchProps {
    loadFiles: (folderID: number) => void
    showFile: (folderID: number, fileID: number) => void
}

type Props = StateProps & DispatchProps

const FileList: React.FC<Props> = (props: Props) => {
    const { id } = useParams<{ id: string }>()
    const { state } = useLocation<any>()

    useEffect(() => {
        const { loadFiles } = props
        loadFiles(Number(id))
    }, [])

    const { files, loading, error, errorMessage } = props

    const download = useCallback(
        (folderID: number, fileID: number) => {
            const { showFile } = props

            showFile(folderID, fileID)
        },
        [files]
    )

    return (
        <>
            {!error ? (
                <>
                    <Subtitle>
                        <h2>{state.title}</h2>
                    </Subtitle>
                    <Container>
                        {loading ? (
                            <>
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                            </>
                        ) : (
                            files.map((file: IDocumentFile) => (
                                <a
                                    href={file.url}
                                    target='_new'
                                    rel='noreferrer'
                                    download
                                    onClick={() =>
                                        download(Number(id), file.darCodigo)
                                    }>
                                    <Card key={file.darCodigo}>
                                        <CardTitle
                                            dangerouslySetInnerHTML={{
                                                __html: file.referencia,
                                            }}
                                        />
                                        <CardButton
                                            onClick={() =>
                                                download(
                                                    Number(id),
                                                    file.darCodigo
                                                )
                                            }>
                                            <img src={iconEnter} alt='' />
                                            {i18n.t('documents.see')}
                                        </CardButton>
                                    </Card>
                                </a>
                            ))
                        )}
                    </Container>
                </>
            ) : (
                <Redirect
                    to={{ pathname: '/error', state: { errorMessage } }}
                />
            )}
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    files: state.documents.documentFiles,
    loading: state.documents.loading,
    error: state.documents.error,
    errorMessage: state.documents.errorMessage,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadFiles: (folderID: number) =>
        dispatch(DocumentsThunk.loadFiles(folderID) as any),
    showFile: (folderID: number, fileID: number) =>
        dispatch(DocumentsThunk.showFile(folderID, fileID) as any),
})

const SkeletonCard: React.FC = () => (
    <Card>
        <Skeleton count={2} width={150} height={25} />
        <CardButton>
            <Skeleton width={35} />
        </CardButton>
    </Card>
)

export default connect(mapStateToProps, mapDispatchToProps)(FileList)
