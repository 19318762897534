import React, { SyntheticEvent, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IClassified } from '../../../store/containers/classified/types';
import { ApplicationState } from '../../../store';
import { ContainerList, Card, CardContainer, CardImage, CardCategory, CardTitle, CardValor, CardButton, CardWrapper } from './style';
import defaultImage from "../../../assets/images/default.jpg"
import ClassifiedSearch from '../components/Search';

interface StateProps {
    services: IClassified[]
}

type Props = StateProps

function imageError(ev: SyntheticEvent<HTMLImageElement, Event>) {
    // eslint-disable-next-line no-param-reassign
    ev.currentTarget.src = defaultImage
}

const Services: React.FC<Props> = ({ services }: Props) => {
    const [query, setQuery] = useState("");
    return (
        <>
            <ClassifiedSearch setQuery={setQuery} />
            <ContainerList>
                {services.filter(service => service.Titulo.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) > -1).map((service: IClassified) => (
                    <Card key={service.CodigoClassificadoItem}>
                        <CardContainer>
                            <CardWrapper>
                                <CardCategory>{service.Categoria}</CardCategory>
                                <CardTitle>{service.Titulo}</CardTitle>
                            </CardWrapper>

                            <CardWrapper>
                                <CardValor>R$ {service.Valor.toFixed(2).toString().replace(".", ",")}</CardValor>
                                <CardButton>
                                    <Link to={{ pathname: `classified/show/${service.CodigoClassificadoItem}` }}>Detalhes</Link>
                                </CardButton>
                            </CardWrapper>

                        </CardContainer>

                        <CardImage>
                            <img onError={imageError} src={service.Imagem1} alt="" />
                        </CardImage>
                    </Card>
                ))}
            </ContainerList>
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    services: state.classifieds.services
})

export default connect(mapStateToProps)(Services);