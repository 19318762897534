import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dispatch } from 'redux'
import { ApplicationState } from '../../../store'
import DocumentsThunk from '../../../store/containers/documents/thunk'
import { IDocumentFolder } from '../../../store/containers/documents/types'

import iconEnter from '../../../assets/images/enter.svg'
import ErrorRoute from '../../../config/ErrorRoute'
import {
    CardBody,
    CardButton,
    CardIcon,
    CardSubtitle,
    CardTitle,
    Container,
    FolderCard,
    Subtitle,
} from './style'

interface StateProps {
    folders: IDocumentFolder[]
    loading: boolean
    error: boolean
    errorMessage: string
}

interface DispatchProps {
    loadFolders: () => void
}

type Props = StateProps & DispatchProps

const FolderList: React.FC<Props> = (props: Props) => {
    useEffect(() => {
        const { loadFolders } = props
        loadFolders()
    }, [])

    const { folders, loading, error, errorMessage } = props
    return (
        <>
            {!error ? (
                <>
                    <Subtitle divider />
                    <Container>
                        {loading ? (
                            <>
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                            </>
                        ) : (
                            folders.map((folder: IDocumentFolder) => (
                                <Link
                                    to={{
                                        pathname: `/docsrh/folder/${folder.dtpCodigo}`,
                                        state: { title: folder.titulo },
                                    }}
                                    key={folder.dtpCodigo}>
                                    <FolderCard key={folder.dtpCodigo}>
                                        <CardIcon>
                                            <FontAwesomeIcon
                                                icon={[
                                                    folder.brand,
                                                    folder.icon,
                                                ]}
                                            />
                                        </CardIcon>
                                        <CardBody>
                                            <CardTitle>
                                                {folder.titulo}
                                            </CardTitle>
                                            <CardSubtitle>
                                                {folder.subtitulo}
                                            </CardSubtitle>
                                            <CardButton>
                                                <img src={iconEnter} alt='' />
                                            </CardButton>
                                        </CardBody>
                                    </FolderCard>
                                </Link>
                            ))
                        )}
                    </Container>
                </>
            ) : (
                <ErrorRoute errorMessage={errorMessage} />
            )}
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    folders: state.documents.documentFolders,
    loading: state.documents.loading,
    error: state.documents.error,
    errorMessage: state.documents.errorMessage,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadFolders: () => dispatch(DocumentsThunk.loadFolders() as any),
})

const SkeletonCard: React.FC = () => (
    <FolderCard>
        <CardIcon>
            <Skeleton circle height={38} width={38} />
        </CardIcon>
        <CardBody>
            <Skeleton width={135} height={24} />
            <Skeleton width={125} height={18} />
            <CardButton>
                <img src={iconEnter} alt='' />
            </CardButton>
        </CardBody>
    </FolderCard>
)

export default connect(mapStateToProps, mapDispatchToProps)(FolderList)
