import { Dispatch } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'
import { parseISO, format } from 'date-fns'
import { api } from '../../../services/api'
import * as ClassifiedActions from './actions'
import { IAnnouncement } from './types'
import ThemeMethods from '../../../styles/themes/themeLibs'

const ClassifiedThunk = {
    loadSetup: () => (dispatch: Dispatch) => {
        dispatch(ClassifiedActions.loadClassifiedRequest())
        api.get('/classificados/setup')
            .then((response: AxiosResponse) => {
                if (response.data?.state) {
                    ThemeMethods.loadTheme(
                        JSON.parse(response.data.data)[0]
                    ).then(() => {
                        dispatch(
                            ClassifiedActions.loadClassifiedSetup(
                                JSON.parse(response.data.data)[0]
                            )
                        )
                    })
                } else {
                    dispatch(
                        ClassifiedActions.loadClassifiedFailure(
                            'SETUP - Erro no Carregamento'
                        )
                    )
                }
            })
            .catch((error: AxiosError) => {
                dispatch(
                    ClassifiedActions.loadClassifiedFailure(
                        `SETUP - ${error.message}`
                    )
                )
            })
    },

    loadAnnouncements: () => (dispatch: Dispatch) => {
        api.get('/classificados/list')
            .then((response: any) => {
                if (response.data?.state) {
                    const products = JSON.parse(response.data.data).filter(
                        (product: IAnnouncement) => product.TipoAnuncio === 0
                    )
                    const services = JSON.parse(response.data.data).filter(
                        (product: IAnnouncement) => product.TipoAnuncio === 1
                    )
                    const myAnnouncements = JSON.parse(
                        response.data.data
                    ).filter(
                        (product: IAnnouncement) => product.meuAnuncio === true
                    )

                    dispatch(ClassifiedActions.loadClassifiedProducts(products))
                    dispatch(ClassifiedActions.loadClassifiedServices(services))
                    dispatch(
                        ClassifiedActions.loadClassifiedMyProducts(
                            myAnnouncements
                        )
                    )
                }
            })
            .catch((error: AxiosError) => {
                dispatch(
                    ClassifiedActions.loadClassifiedFailure(
                        `LISTA - ${error.message}`
                    )
                )
            })
    },
    loadAnnouncement: (announcement: number) => (dispatch: Dispatch) => {
        api.get(`/classificados/show/${announcement}`)
            .then((response: any) => {
                if (response.data.state) {
                    const data: IAnnouncement = JSON.parse(
                        response.data.data
                    )[0]
                    if (data) {
                        data.DataCriacao =
                            data.DataCriacao &&
                            format(parseISO(data.DataCriacao), "dd'/'MM'/'yyyy")
                        data.Valor = data.Valor
                            ? data.Valor.toFixed(2).toString().replace('.', ',')
                            : '0,00'

                        dispatch(ClassifiedActions.loadAnnouncement(data))
                    } else {
                        dispatch(
                            ClassifiedActions.loadClassifiedFailure(
                                'Dados não encontrados!'
                            )
                        )
                    }
                } else {
                    dispatch(
                        ClassifiedActions.loadClassifiedFailure(
                            'Dados não encontrados!'
                        )
                    )
                }
            })
            .catch((error: AxiosError) => {
                dispatch(
                    ClassifiedActions.loadClassifiedFailure(
                        `SHOW - ${error.message}`
                    )
                )
            })
    },
    unloadAnnouncement: () => (dispatch: Dispatch) => {
        dispatch(ClassifiedActions.unloadAnnouncement())
    },
    loadCategories: () => (dispatch: Dispatch) => {
        api.get('/classificados/categorialista')
            .then((response: any) => {
                if (response.data.state) {
                    dispatch(
                        ClassifiedActions.loadClassifiedCategories(
                            JSON.parse(response.data.data)
                        )
                    )
                } else {
                    dispatch(ClassifiedActions.loadClassifiedCategories([]))
                }
            })
            .catch((error: AxiosError) => {
                dispatch(
                    ClassifiedActions.loadClassifiedFailure(`${error.message}`)
                )
            })
    },
    loadAnnouncementTypes: () => (dispatch: Dispatch) => {
        api.get('/classificados/tipoanunciolista')
            .then((response: any) => {
                if (response.data.state) {
                    dispatch(
                        ClassifiedActions.loadAnnouncementTypes(
                            JSON.parse(response.data.data)
                        )
                    )
                } else {
                    dispatch(ClassifiedActions.loadAnnouncementTypes([]))
                }
            })
            .catch((error: AxiosError) => {
                dispatch(
                    ClassifiedActions.loadClassifiedFailure(`${error.message}`)
                )
            })
    },
    loadTerms: () => (dispatch: Dispatch) => {
        api.get('/classificados/termosdeuso')
            .then((response: any) => {
                if (response.data.state) {
                    dispatch(ClassifiedActions.setTerms(response.data.url))
                }
            })
            .catch((error: AxiosError) => {
                dispatch(
                    ClassifiedActions.loadClassifiedFailure(`${error.message}`)
                )
            })
    },
    storeAnnouncement:
        (
            data: any,
            imageData: Array<FormData>,
            announcementID?: number | null
        ) =>
        (dispatch: Dispatch) => {
            const salvarURL = !announcementID
                ? '/classificados/salvar'
                : `/classificados/salvar/${announcementID}`
            api.post(salvarURL, data)
                .then((response: any) => {
                    if (response.data.state) {
                        const id = JSON.parse(response.data.data)[0]
                            .CodigoClassificadoItem

                        if (imageData.length > 0) {
                            const storeImages = new Promise<void>(
                                (res, rej) => {
                                    imageData.forEach(
                                        (image: FormData, index, array) => {
                                            ClassifiedThunk.storeImage(
                                                id,
                                                image
                                            ).then(() => {
                                                if (index === array.length - 1)
                                                    res()
                                            })
                                            // .catch((error) => console.log(error))
                                        }
                                    )
                                }
                            )
                            storeImages.then(() => {
                                ClassifiedThunk.loadAnnouncements()(dispatch)
                            })
                        } else {
                            ClassifiedThunk.loadAnnouncements()(dispatch)
                        }
                    } else {
                        dispatch(
                            ClassifiedActions.loadClassifiedFailure(
                                response.data.message
                            )
                        )
                    }
                })
                .catch((error: AxiosError) => {
                    dispatch(
                        ClassifiedActions.loadClassifiedFailure(
                            `${error.message}`
                        )
                    )
                })
        },
    storeImage: (announcementID: number, imageData: FormData) =>
        new Promise<void>((res, rej) => {
            api.post(
                `/classificados/enviarimagem/${announcementID}`,
                imageData,
                { timeout: 0 }
            ).then((response: any) => {
                if (response.data.state) {
                    res()
                }
                rej()
            })
        }),
    removeAnnouncement: (announcementID: number) => (dispatch: Dispatch) => {
        api.post(`/classificados/excluir/${announcementID}`, announcementID)
            .then((response: any) => {
                if (response.data.state) {
                    ClassifiedThunk.loadAnnouncements()(dispatch)
                }
            })
            .catch((error: AxiosError) => {
                dispatch(
                    ClassifiedActions.loadClassifiedFailure(`${error.message}`)
                )
            })
    },
    sellProduct: (announcementID: number) => (dispatch: Dispatch) => {
        api.post(`/classificados/vender/${announcementID}`, {
            Vendido: true,
            VendidoPorCanal: 1,
        })
            .then((response: any) => {
                if (response.data.state) {
                    ClassifiedThunk.loadAnnouncements()(dispatch)
                }
            })
            .catch((error: AxiosError) => {
                // dispatch(ClassifiedActions.loadClassifiedFailure(`${error.message}`))
            })
    },
    loadSellerData: (id: number) => (dispatch: Dispatch) => {
        api.get(`/classificados/vendedor/${id}`)
            .then((response: any) => {
                dispatch(ClassifiedActions.loadSellerData(response.data[0]))
            })
            .catch((error: AxiosError) => {
                // dispatch(ClassifiedActions.loadClassifiedFailure(`${error.message}`))
            })
    },
}

export default ClassifiedThunk
