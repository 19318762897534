import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 15px;
  margin-top: 30px;
`;

export const TermHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TermContainer = styled.div`
  position: relative;
`

export const Divider = styled.div`
  width: 100%;
  height: 15px;
  background-color: ${props => props.theme.colors.primary};
`

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 700;
`