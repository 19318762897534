
// eslint-disable-next-line no-shadow
export enum ClassifiedTypes {

    LOAD_CLASSIFIED_SETUP = "@classified/LOAD_CLASSIFIED_SETUP",

    LOAD_CLASSIFIED_REQUEST = "@classified/LOAD_CLASSIFIED_REQUEST",
    LOAD_CLASSIFIED_PRODUCTS = "@classified/LOAD_CLASSIFIED_PRODUCTS",
    LOAD_CLASSIFIED_SERVICES = "@classified/LOAD_CLASSIFIED_SERVICES",
    LOAD_CLASSIFIED_MY_PRODUCTS = "@classified/LOAD_CLASSIFIED_MY_PRODUCTS",

    LOAD_ANNOUNCEMENT = "@classified/LOAD_ANNOUNCEMENT",
    UNLOAD_ANNOUNCEMENT = "@classified/UNLOAD_ANNOUNCEMENT",

    LOAD_CLASSIFIED_FAILURE = "@classified/LOAD_CLASSIFIED_FAILURE",

    LOAD_SELLER_DATA = "@classified/LOAD_SELLER_DATA",

    LOAD_CLASSIFIED_CATEGORIES = "@classified/LOAD_CLASSIFIED_CATEGORIES",
    LOAD_CLASSIFIED_TERMS = "@classified/LOAD_CLASSIFIED_TERMS",
    LOAD_CLASSIFIED_ANNOUNCEMENT_TYPES = "@classified/LOAD_CLASSIFIED_ANNOUNCEMENT_TYPES",

    SET_CLASSIFIED_TERMS = "@classified/SET_CLASSIFIED_TERMS",
}

export interface IClassified {
    CodigoClassificadoItem: number,
    CodigoColaborador: number,
    TipoAnuncio: number,
    Categoria: number,
    TipoUso: number,
    Titulo: string,
    Imagem1: string,
    Valor: number,
    curtiu: boolean,
    StatusAprovacaoCodigo: number,
    StatusAprovacao: string,
    Vendido: boolean
}

export interface IAnnouncement {
    CodigoClassificadoItem: number,
    AceitaTroca: boolean,
    Categoria: number,
    DataCriacao: string,
    DataExclusao?: string,
    DataPublicacao: string,
    DataTermino?: string,
    DataVenda?: string,
    Descricao: string,
    EmailContato: string,
    Imagem1: string,
    Imagem2: string,
    Imagem3: string,
    MotivoRejeicao?: string,
    ObservacaoInterna?: string,
    StatusAprovacaoCodigo: number,
    Telefone?: string,
    TelefoneContato: string,
    TermoAceito: number,
    TipoAnuncio: number,
    TipoUso: number,
    Titulo: string,
    Valor: any,
    Vendido: boolean,
    VendidoPorCanal: number,
    Visualizacoes: number,
    curtiu: boolean,
    meuAnuncio: boolean,
    usuario: number
}

export interface IClassifiedCategories {
    id: number,
    descricao: string,
}

export interface IClassifiedTypeAnnouncement {
    id: number,
    descricao: string
}
export interface IClassifiedSetup {
    RequerAprovacaoProduto: boolean,
    RequerAprovacaoServico: boolean,
    TermosDeUso: string,
    LimiteAnunciosColab: number,
    PermitirTipos: IClassifiedTypeAnnouncement[],
    EsconderHeader: boolean
}

export interface IClassifiedSeller {
    Nome: string,
    Email: string,
    Telefone: string,
    Foto: string
}

export interface ClassifiedState {
    readonly setup: IClassifiedSetup;
    readonly products: IClassified[],
    readonly services: IClassified[],
    readonly seller: IClassifiedSeller,
    readonly myAnnouncements: IClassified[],
    readonly actualAnnouncement: IAnnouncement,
    readonly categories: IClassifiedCategories[],
    readonly announcementTypes: IClassifiedTypeAnnouncement[],
    readonly terms: string,
    readonly loading: boolean,
    readonly error: boolean,
    readonly errorMessage: string
}