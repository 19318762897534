import React, { SyntheticEvent } from 'react';

import { Container } from './style';

type Props = {
    setQuery: (query: string) => any
}

const ClassifiedSearch: React.FC<Props> = ({ setQuery }: Props) => {

    const handleSearch = (form: HTMLFormElement) => {
        const query = form.query.value;
        setQuery(query);
    }

    return (
        <Container>
            <form onSubmit={(e: SyntheticEvent) => { e.preventDefault(); handleSearch(e.target as HTMLFormElement) }}>
                <input type="text" name="query" placeholder="Pesquisa" />
            </form>
        </Container>
    );
}

export default ClassifiedSearch;