import React from 'react'
import Switch from '../../../components/Switch'
import {
    IAccessibilityProps,
    TSettingsAvailable,
    TSettingsField,
} from '../../../store/containers/settings/types'
import { i18n } from '../../../translate/i18n'
import {
    FormContainer,
    FormGroup,
    Label,
    Select,
    Title,
} from '../Screen/styles'
import useDebounce from '../../../hooks/useDebounce'

const Accessibility: React.FC<IAccessibilityProps> = ({
    modoNoturno,
    filtroCor,
    contraste,
    tamanhoFonte,
    libras,
    leituraVoz,
    setSettingsState,
    settingsAvailable,
}) => {
    const handleDebounceChange = useDebounce(
        (field: TSettingsField, value: string) => {
            setSettingsState(field, value)
        },
        2000
    )

    const handleChange = (field: TSettingsField, value: any) => {
        setSettingsState(field, value)
    }

    if (
        !settingsAvailable.modoNoturno &&
        !settingsAvailable.filtroCor &&
        !settingsAvailable.contraste &&
        !settingsAvailable.tamanhoFonte &&
        !settingsAvailable.leituraLibras &&
        !settingsAvailable.leituraVoz
    )
        return null

    return (
        <div>
            <Title>{i18n.t('settings.accessibility')}</Title>
            <FormContainer>
                {settingsAvailable.modoNoturno && (
                    <FormGroup inline>
                        <Label htmlFor='modoNoturno'>
                            {i18n.t('settings.dark-mode')}
                        </Label>
                        <Switch
                            value={modoNoturno}
                            onChange={() =>
                                handleChange('modoNoturno', !modoNoturno)
                            }
                        />
                    </FormGroup>
                )}

                {settingsAvailable.filtroCor && (
                    <FormGroup>
                        <Label htmlFor='filtroCor'>
                            {i18n.t('settings.color-filter')}
                        </Label>
                        <Select
                            value={filtroCor}
                            name='filtroCor'
                            id='filtroCor'
                            onChange={(event) => {
                                handleChange(
                                    'filtroCor',
                                    event.currentTarget.value
                                )
                            }}>
                            <option value='pro'>
                                {i18n.t('settings.color-filter-protoanopia')}
                            </option>
                            <option value='deu'>
                                {i18n.t('settings.color-filter-deuteranopia')}
                            </option>
                            <option value='tri'>
                                {i18n.t('settings.color-filter-tritanopia')}
                            </option>
                        </Select>
                    </FormGroup>
                )}

                {settingsAvailable.contraste && (
                    <FormGroup>
                        <Label htmlFor='contraste'>
                            {i18n.t('settings.contraste')}
                        </Label>
                        <Select
                            value={contraste}
                            name='contraste'
                            id='contraste'
                            onChange={(event) =>
                                handleChange(
                                    'contraste',
                                    event.currentTarget.value
                                )
                            }>
                            <option value='med'>
                                {i18n.t('settings.contraste-normal')}
                            </option>
                            <option value='bai'>
                                {i18n.t('settings.contraste-low')}
                            </option>
                            <option value='alt'>
                                {i18n.t('settings.contraste-high')}
                            </option>
                        </Select>
                    </FormGroup>
                )}

                {settingsAvailable.tamanhoFonte && (
                    <FormGroup>
                        <Label htmlFor='tamanhoFonte'>
                            {i18n.t('settings.font-size')}
                        </Label>
                        <Select
                            value={tamanhoFonte}
                            name='tamanhoFonte'
                            id='tamanhoFonte'
                            onChange={(event) =>
                                handleChange(
                                    'tamanhoFonte',
                                    event.currentTarget.value
                                )
                            }>
                            <option value='med'>
                                {i18n.t('settings.font-size-normal')}
                            </option>
                            <option value='peq'>
                                {i18n.t('settings.font-size-small')}
                            </option>
                            <option value='gra'>
                                {i18n.t('settings.font-size-large')}
                            </option>
                        </Select>
                    </FormGroup>
                )}

                {settingsAvailable.leituraLibras && (
                    <FormGroup inline>
                        <Label htmlFor='libras'>
                            {i18n.t('settings.libras')}
                        </Label>
                        <Switch
                            value={libras}
                            onChange={() =>
                                handleChange('leituraLibras', !libras)
                            }
                        />
                    </FormGroup>
                )}

                {settingsAvailable.leituraVoz && (
                    <FormGroup inline>
                        <Label htmlFor='leituraVoz'>
                            {i18n.t('settings.text-to-speech')}
                        </Label>
                        <Switch
                            value={leituraVoz}
                            onChange={() =>
                                handleChange('leituraVoz', !leituraVoz)
                            }
                        />
                    </FormGroup>
                )}
            </FormContainer>
        </div>
    )
}

export default Accessibility
