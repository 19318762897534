import React, { useCallback } from 'react'

import { TabButton } from './style'

type Props = {
    title: string
    index: number
    setSelectedTab: (index: number) => void
    selectedTab: any
    setWidth: (width: number) => void
    setPosition: (position: number) => void
}

const TabTitle: React.FC<Props> = ({
    title,
    setSelectedTab,
    index,
    selectedTab,
    setWidth,
    setPosition,
}: Props) => {
    const clickHandler = useCallback(
        (event: any) => {
            setSelectedTab(index)

            setWidth(event.target.offsetWidth)
            setPosition(event.target.offsetLeft)
        },
        [setSelectedTab, index]
    )

    return (
        <li>
            <TabButton onClick={clickHandler} isActive={index === selectedTab}>
                {title}
            </TabButton>
        </li>
    )
}

export default TabTitle
