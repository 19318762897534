/* eslint-disable no-shadow */
export enum UserTypes {
    LOAD_USER_REQUEST = "@user/LOAD_USER_REQUEST",
    LOAD_USER_SUCCESS = "@user/LOAD_USER_SUCCESS",
    LOAD_USER_FAILURE = "@user/LOAD_USER_FAILURE"
}

export interface IUser {
    CodigoColaborador: number,
    NomeColaborador: string,
    FuncaoColaborador: string,
    EmailColaborador: string,
    TelefoneContato: string,
    Foto: string
}

export interface UserState {
    readonly user: IUser,
    readonly loading: boolean,
    readonly error: boolean
}