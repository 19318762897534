import { Reducer } from 'redux'
import { ITerm, TermState, TermTypes } from './types'

const INITIAL_STATE: TermState = {
    terms: [],
    activeTerm: {} as ITerm,
    error: false,
    loading: false,
    errorMessage: '',
}

const reducer: Reducer<TermState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TermTypes.LOAD_TERM_SETUP:
            return { ...state, loading: false }
        case TermTypes.LOAD_TERM_REQUEST:
            return { ...state, loading: true }
        case TermTypes.LOAD_TERM_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                terms: action.payload.data,
            }
        case TermTypes.LOAD_TERM_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload,
            }
        case TermTypes.LOAD_SINGLE_TERM_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                activeTerm: action.payload.data,
            }
        default:
            return state
    }
}

export default reducer
