/* eslint-disable no-constant-condition */
import styled from "styled-components";

import play from "../../../../../assets/images/play.png";
import replay from "../../../../../assets/images/replay.png";

interface Training {
  status: number;
}

export const termButtons = [
  { key: 0, icon: play, text: "Iniciar" },
  { key: 1, icon: replay, text: "Rever" }
];

export const Card = styled.div<Training>`
    position: relative;

    background-color: white;
    border: 2px solid ${(props) => props.theme.colors.primary}${(props) => (props.status === 0 ? "FF" : "30")};
    border-radius: 10px;

    display: flex;
    align-items: center;

    padding: 20px 20px;
    margin: 10px 0 20px;

    width: 100%;
  a {
    width: 100%;
    color: inherit;

    display: flex;
    align-items: center;
  }
`;

export const CardImage = styled.img`
  height: 60px;
  width: 60px;
  border: 2px solid white;
  border-radius: 50%;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.12));
`;

export const CardBody = styled.div`
  margin-left: 10px;
`;

export const CardTitle = styled.h2`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;

export const CardDepartment = styled.p`
  font-size: 15px;
  font-weight: 400;
`;

export const CardButton = styled.div<{status: number}>`
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 10px 0px;
  padding: 8px 12px;
  display: flex;
  align-items: center;

  background-color: ${(props) => props.theme.trainingButtons.find((button) => button.key === props.status).color}08;
  
  span {
    position: relative;
    color: ${(props) => props.theme.trainingButtons.find((button) => button.key === props.status).color};
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;

    display: flex;
    align-items: center;
    line-height: 20px;

    img {
      margin-right: 5px;
    }
  }
`;
