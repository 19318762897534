import React from 'react'
import { connect } from 'react-redux'
import AniverSVG from '../../assets/images/aniversariantes.svg'
import { ApplicationState } from '../../store'
import { IBirthworkSetup } from '../../store/containers/birthwork/types'
import { Container, Description, TextWrapper, Title } from './styles'

interface StateProps {
    setup: IBirthworkSetup
}

type Props = StateProps

const BirthworkCard: React.FC<Props> = ({ setup }) => {
    if (!setup.CardTitulo || !setup.CardDescricao) return null
    return (
        <Container>
            <img src={AniverSVG} alt='' className='icon' />
            <TextWrapper>
                <Title>{setup.CardTitulo}</Title>
                <Description>{setup.CardDescricao}</Description>
            </TextWrapper>
        </Container>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    setup: state.birthwork.setup,
})

export default connect(mapStateToProps)(BirthworkCard)
