import React, { ReactElement, useState } from 'react';

import TabTitle from './TabTitle'
import TabActive from './TabActive';

import { TabContainer, TabList } from './style';

type Props = {
    children: ReactElement[],
    initialWidth: number,
    initialPosition: number
}

const Tabs: React.FC<Props> = ({ children, initialWidth, initialPosition }: Props) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const [width, setWidth] = useState(initialWidth ?? 0)
    const [position, setPosition] = useState(initialPosition ?? 0)

    return (
        <>
            <TabContainer>
                <TabList>
                    {children.map((item: ReactElement, index) => (
                        item.props.show &&
                        <TabTitle
                            key={index}
                            title={item.props.title}
                            index={index}
                            setSelectedTab={setSelectedTab}
                            selectedTab={selectedTab}
                            setWidth={setWidth}
                            setPosition={setPosition}
                        />
                    ))}
                </TabList>
                <TabActive position={position} width={width} />
            </TabContainer>
            {children[selectedTab]}
        </>
    );
}

export default Tabs;