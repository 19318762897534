interface ValidatorResponse {
    isValid: boolean,
    errorMessage: string
}

async function validateFileSize(fileSize: number, maxFileSize: number): Promise<ValidatorResponse> {
    const FileSizeValidator = (await import('../validators/FileSizeValidator')).default

    const validator = new FileSizeValidator(fileSize, maxFileSize)
    const isValid = validator.validateFileSize()

    return {
        isValid,
        errorMessage: isValid ? "" : validator.getErrorMessage()
    }
}

async function validateFileType(fileType: string, validTypes: string[]): Promise<ValidatorResponse> {
    const FileTypeValidator = (await import('../validators/FileTypeValidator')).default

    const validator = new FileTypeValidator(fileType, validTypes)
    const isValid = validator.validateFileTypes()

    return {
        isValid,
        errorMessage: isValid ? "" : validator.getErrorMessage()
    }
}

export { validateFileSize, validateFileType }