
/* eslint-disable camelcase */
// eslint-disable-next-line no-shadow
export enum GiftTypes {
    LOAD_GIFT_SETUP = "@gifts/LOAD_GIFT_SETUP",
    LOAD_GIFT_REQUEST = "@gifts/LOAD_GIFT_REQUEST",
    LOAD_GIFT_SUCCESS = "@gifts/LOAD_GIFT_SUCCESS",
    LOAD_GIFT_FAILURE = "@gifts/LOAD_GIFT_FAILURE",

    RECEIVED_GIFT_SUCCESS = "@gifts/RECEIVED_GIFT_SUCCESS",
    RECEIVED_GIFT_FAILURE = "@gifts/RECEIVED_GIFT_FAILURE",
    RECEIVED_GIFT_REQUEST = "@gifts/RECEIVED_GIFT_REQUEST",

    SET_PIN = "@gifts/SET_PIN"
}

/**
 * Data Types
 */

export interface IGift {
    descritivo: string,
    ultimoResgate: string,
    status: string
}

export interface IGiftSetup {
    apps_brindes_descricao: string
    apps_brindes_titulo: string
    apps_brindes_informativo: string
    apps_brindes_botao: string
    apps_brindes_semitens: string
}

/**
 * State Types
 */

export interface GiftState {
    readonly gifts: IGift,
    readonly giftSetup: IGiftSetup,
    readonly loading: boolean,
    readonly error: boolean,
    readonly errorMessage: string,
    readonly pin: string,

    readonly receivedLoading: boolean,
    readonly receivedError: boolean
    readonly receivedErrorMessage: string
}