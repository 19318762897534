import React from 'react'

type Props = {
    title: string
    show: boolean
    children: React.ReactNode
}

// eslint-disable-next-line react/prop-types
const Tab: React.FC<Props> = ({ children }) => <>{children}</>

export default Tab
