import { asyncLocalStorage } from '../../auth/auth'
import simplifica from './simplifica'

const ThemeMethods = {
    loadTheme: ({
        TituloModulo,
        CorPrimaria,
        CorSecundaria,
        EsconderHeader,
    }: any) => {
        return new Promise<void>((resolve) =>
            asyncLocalStorage
                .setItem('@services_page_title', TituloModulo)
                .then(() => {
                    simplifica.colors.primary = CorPrimaria
                    simplifica.colors.secondary = CorSecundaria

                    simplifica.trainingButtons[0].color = CorPrimaria
                    simplifica.trainingButtons[1].color = CorPrimaria

                    simplifica.classifiedCategories.tipoUso[0].background =
                        CorSecundaria
                })
                .then(() =>
                    asyncLocalStorage.setItem(
                        '@services_header',
                        EsconderHeader
                    )
                )
                .finally(() => {
                    resolve()
                })
        )
    },
}

export default ThemeMethods
