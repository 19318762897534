import { Container, Loader } from './styles'

const SimpleLoading: React.FC = () => {
    return (
        <Container>
            <Loader />
        </Container>
    )
}

export default SimpleLoading
