import { createGlobalStyle } from "styled-components";

export default createGlobalStyle<{ tema: any }>`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        background-color: ${(props) => props.theme.colors.background};
        font-family: Lato;
        color: ${(props) => props.theme.colors.text};
    }

    a {
        text-decoration: none;
    }
`;