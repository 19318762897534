import styled from 'styled-components';
import inputText from '../../../../../assets/images/input_edit.svg';
import selectArrow from '../../../../../assets/images/select_arrow.svg'
import imageFile from '../../../../../assets/images/image_file.svg';

export const Form = styled.form`
    position: relative;
`

export const FormGroup = styled.div<{ flex?: boolean, margin?: string }>`
    position: relative;
    border: none;
    ${props => props.flex ? "display: flex; justify-content: space-between;"
        : "display: block"};

    ${props => props.margin ? `margin: ${props.margin};` : 'margin-bottom: 15px;'}

    fieldset {
        justify-content: start;
    }
`

export const Select = styled.select<{ selectedValue?: string, error?: string }>`
    width: 100%;
    padding: 15px 5px;
    background-color: ${props => props.error ? `${props.theme.colors.danger}10` : `#fff`};
    font-size: 18px;
    color: ${props => props.selectedValue === "#" ? "#767676" : props.theme.colors.text};
    background-image: url('${selectArrow}');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    
    border: none;
    border-bottom: 1px solid ${props => props.error ? props.theme.colors.danger : `#ddd`};
    appearance: none;
    outline:none;
    
    :focus{
        box-shadow:0px 4px 7px -5px ${props => props.theme.colors.primary}33
    }
`

export const Option = styled.option``

export const Label = styled.label`
    font-size: 18px;
    font-weight: 700;
    color: ${props => props.theme.colors.text};
    line-height: 30px;
`

export const Currency = styled.span`
    position: absolute;
    top: 25px;
    left: 5px;
    transform: translateY(-50%);
    color: #666;
    font-size: 18px;
    z-index:10;
    padding-right: 15px;

    border-right: 1px solid #ddd;
`

export const Input = styled.input<{ error?: string }>`
    position: relative;
    width: 100%;
    padding: 15px 5px;
    background-color: ${props => props.error ? `${props.theme.colors.danger}10` : `#fff`};
    font-size: 18px;
    color: ${props => props.theme.colors.text};

    background-image: url('${inputText}');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;

    border: none;
    border-bottom: 1px solid ${props => props.error ? props.theme.colors.danger : `#ddd`};

    outline:none;
`

export const ValidateInput = styled.p`
    font-size: 12px;
    color : ${props => props.theme.colors.danger};
`

export const Icon = styled.img`
    position: absolute;
    right: 0;
    top: 50%;
    filter: opacity(0.5);
`

export const ImageFile = styled.input`
    background-image: url(${imageFile});
`

export const FileInfo = styled.h3`
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 700;

    >span {
        display: block;
        font-style: italic;
        font-size: 11px;
        font-weight: 400;
    }
`

export const TermsTitle = styled.h3`
    font-size: 18px;
    margin: 8px 0;
`

export const Terms = styled.div`
    height: 150px;

    embed {
        width: 100%;

        html {
            font-family: 'Lato';
        }
    }
    input { 
        display:none;
    }
`

export const Divider = styled.hr`
    margin-bottom: 20px;
    border:none;
    border-bottom: 1px solid #EBEBEB;
`

export const AproveNotification = styled.p`
    font-size: 13px;
    color: #767676;
    width: 50%;
`

export const Submit = styled.button`
    display: flex;
    align-items: center;
    gap: 5px;
    background-color:  ${props => props.theme.colors.primary};
    font-size: 18px;
    font-weight: 700;
    color: white;
    padding: 10px 15px;

    border: none;
    border-radius: 10px;
`