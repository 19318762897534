import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import Tabs from '../../../components/BirthdayTabs'
import BirthworkCard from '../../../components/BirthworkCard'
import Breadcrumb from '../../../components/Breadcrumb'
import { ApplicationState } from '../../../store'
import BirthworkThunk from '../../../store/containers/birthwork/thunk'
import {
    IBirthwork,
    IBirthworkSetup,
} from '../../../store/containers/birthwork/types'
import { Container } from './styles'

interface StateProps {
    loading: boolean
    birthwork: Array<IBirthwork>
    setup: IBirthworkSetup
}

interface DispatchProps {
    loadBirthworks: () => void
}

type Props = StateProps & DispatchProps

const BirthworkList: React.FC<Props> = (props: Props) => {
    const { loading, birthwork, setup } = props

    useEffect(() => {
        const { loadBirthworks } = props
        loadBirthworks()
    }, [])

    return (
        <Container>
            <Breadcrumb show={true} />
            <BirthworkCard />
            <Tabs
                birthdays={birthwork}
                type='birthwork'
                hasHeader={!setup.EsconderHeader}
            />
        </Container>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.birthwork.loading,
    birthwork: state.birthwork.birthwork,
    setup: state.birthwork.setup,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadBirthworks: () => dispatch(BirthworkThunk.loadBirthworks() as any),
})

export default connect(mapStateToProps, mapDispatchToProps)(BirthworkList)
