import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: white;
`;

export const Value = styled(Title)`
  display: inline-flex;
  align-items: center;
  span {
    font-size: 30px;
  }
`;
