import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../store';
import TrainingThunk from '../../store/containers/training/thunk';
import Loader from '../../components/Loader';

interface StateProps {
    loading: boolean,
    error: boolean,
    errorMessage: string
}

interface DispatchProps {
    loadSetup(hash: string): void
}

type Props = StateProps & DispatchProps

const Training: React.FC<Props> = (props: Props) => {
    const { hash } = useLocation()

    useEffect(() => {
        const { loadSetup } = props
        loadSetup(hash)
    }, [])

    const { loading, error, errorMessage } = props

    return (
        <Loader loading={loading} error={error} errorMessage={errorMessage}>
            <Redirect to={{ pathname: '/training/list' }} />
        </Loader>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.trainings.loading,
    error: state.trainings.error,
    errorMessage: state.trainings.errorMessage
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadSetup: (token: string) => dispatch(TrainingThunk.loadSetup(token) as any),
})

export default connect(mapStateToProps, mapDispatchToProps)(Training)