import styled from 'styled-components'

const width = window.innerWidth - 32

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`

export const TabsContainer = styled.div<{ hasHeader: boolean }>`
    position: sticky;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    padding: 12px 16px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.background};
    z-index: 5;
    top: ${({ hasHeader }) => (hasHeader ? '60px' : '0')};
`

export const TabIcon = styled.img`
    margin-right: 4px;
    width: 16px;
`

export const TabItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

export const TabTitle = styled.h3<{ isActive: boolean }>`
    font-size: 16px;
    font-weight: bold;
    color: ${(props) =>
        props.isActive
            ? props.theme.colors.primary
            : props.theme.colors.textMuted};
    margin-left: 4px;
    transition: color 0.4s ease-in-out;
`

export const TabIndicator = styled.div<{ activeTab: number }>`
    width: ${width / 3}px;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.primary};
    position: absolute;
    left: ${({ activeTab }) => activeTab * (width / 3) + 16}px;
    bottom: 0;
    transition: all 0.2s ease-in-out;
`
