/* eslint-disable react/no-children-prop */
import * as React from "react";
import { ConfirmationDialog, ConfirmationOptions } from "./confirmation-modal";

type Props = {
    children: React.ReactElement
}

const ConfirmationServiceContext = React.createContext<(options: ConfirmationOptions) => Promise<void>>(Promise.reject.bind(Promise));

export const useConfirmation = () => React.useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider = ({ children }: Props) => {
    const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null);

    const awaitingPromiseRef = React.useRef<{ resolve: () => void; reject: () => void; }>();

    const openConfirmation = (options: ConfirmationOptions) => {
        setConfirmationState(options);
        return new Promise<void>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }

        setConfirmationState(null);
    };

    const handleSubmit = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve();
        }

        setConfirmationState(null);
    };

    return (
        <>
            <ConfirmationServiceContext.Provider
                value={openConfirmation}
                children={children}
            />

            <ConfirmationDialog
                open={Boolean(confirmationState)}
                onSubmit={handleSubmit}
                onClose={handleClose}
                {...confirmationState}
            />
        </>
    );
};
