import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { ReactComponent as CalendarDay } from '../../assets/images/calendar_day.svg'
import { ReactComponent as CalendarMonth } from '../../assets/images/calendar_month.svg'
import { ReactComponent as CalendarWeek } from '../../assets/images/calendar_week.svg'
import List from '../../pages/Birthdays/list/components/List'
import {
    IBirthday,
    TBirthdayType,
} from '../../store/containers/birthdays/types'
import { IBirthwork } from '../../store/containers/birthwork/types'
import { i18n } from '../../translate/i18n'
import { TabIndicator, TabItem, TabTitle, TabsContainer } from './styles'

interface BirthdaysTabsProps {
    hasHeader: boolean
    birthdays: Array<IBirthday> | Array<IBirthwork>
    type: TBirthdayType
}

type BirthdaysTabs = Array<IBirthday[]> | Array<IBirthwork[]>

const Tabs: React.FC<BirthdaysTabsProps> = (props) => {
    const { birthdays, type, hasHeader } = props

    const [activeTab, setActiveTab] = useState(0)
    const theme = useTheme()
    const tabs = [
        {
            title: i18n.t('general.day'),
            icon: (
                <CalendarDay
                    fill={
                        activeTab === 0
                            ? theme.colors.primary
                            : theme.colors.textMuted
                    }
                />
            ),
        },
        {
            title: i18n.t('general.week'),
            icon: (
                <CalendarWeek
                    fill={
                        activeTab === 1
                            ? theme.colors.primary
                            : theme.colors.textMuted
                    }
                />
            ),
        },
        {
            title: i18n.t('general.month'),
            icon: (
                <CalendarMonth
                    fill={
                        activeTab === 2
                            ? theme.colors.primary
                            : theme.colors.textMuted
                    }
                />
            ),
        },
    ]

    const [tabContent, setTabContent] = useState<BirthdaysTabs>([])

    const handleTabClick = (index: number) => {
        setActiveTab(index)
    }

    useEffect(() => {
        if (birthdays && birthdays.length > 0) {
            const birthdaysByDay = birthdays.filter(
                (birthday) => birthday.noDia
            )
            const birthdaysByWeek = birthdays.filter(
                (birthday) => birthday.naSemana
            )
            const birthdaysByMonth = birthdays.filter((birthday) => birthday)
            setTabContent([birthdaysByDay, birthdaysByWeek, birthdaysByMonth])
        }
    }, [birthdays])

    return (
        <>
            <TabsContainer hasHeader={hasHeader}>
                {tabs.map((tab, index) => (
                    <TabItem
                        key={`tab-${index}`}
                        onClick={() => handleTabClick(index)}>
                        {tab.icon}
                        <TabTitle isActive={activeTab === index}>
                            {tab.title}
                        </TabTitle>
                    </TabItem>
                ))}
                <TabIndicator activeTab={activeTab} />
            </TabsContainer>
            <List
                birthdays={tabContent[activeTab]}
                activeTab={activeTab}
                cardType={type}
            />
        </>
    )
}

export default Tabs
