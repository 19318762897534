import React from 'react';
import { Embed } from './style'

type Props = {
    text: string
}

const Terms: React.FC<Props> = ({ text }: Props) => (
    <Embed dangerouslySetInnerHTML={text ? { __html: text } : { __html: "" }} />
)


export default Terms;