import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { IClassifiedSeller } from '../../../../../store/containers/classified/types';

import { Container, SellerPhoto, SellerHeader, SellerName, SellerMail, SellerPhone } from './style';

type Props = { seller: IClassifiedSeller }

const SellerCard: React.FC<Props> = (props: Props) => {
    const { seller } = props

    return (
        <Container>
            {
                seller ?
                    <SellerPhoto src={seller.Foto} />
                    :
                    <Skeleton circle height={48} width={48} />
            }

            <SellerHeader>
                <SellerName>{seller.Nome || <Skeleton width={150} />}</SellerName>
            </SellerHeader>
            <SellerMail>{seller.Email || <Skeleton width={150} />}</SellerMail>
            <SellerPhone>{seller.Telefone || <Skeleton width={150} />}</SellerPhone>
        </Container>
    )
}

export default SellerCard