import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'

import { ConfirmationServiceProvider } from './components/Modal/Confirmation/confirmation-service'
import Routes from './config/routes'
import store from './store'
import GlobalStyles from './styles/global'
import simplifica from './styles/themes/simplifica'

import { Container } from './style'

library.add(fab, fas, far)

const App: React.FC = () => {
    useEffect(
        () => () => {
            localStorage.clear()
        },
        []
    )
    return (
        <Provider store={store}>
            <Container>
                <GlobalStyles tema={simplifica} />
                <ConfirmationServiceProvider>
                    <Routes />
                </ConfirmationServiceProvider>
            </Container>
        </Provider>
    )
}
export default App
