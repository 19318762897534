import { useEffect, useRef } from 'react'

const useDebounce = (callback: any, delay: number) => {
    const timeoutRef = useRef<any>()

    useEffect(() => {
        if (timeoutRef.current) {
            return () => clearTimeout(timeoutRef.current)
        }
    }, [])

    const debouncedCallback = (...args: any) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }

        timeoutRef.current = setTimeout(() => {
            callback(...args)
        }, delay)
    }
    return debouncedCallback
}

export default useDebounce
