import React from 'react';
import { useLocation } from 'react-router';

import { Container } from "./style"

type Props = {
    errorMessage?: string
}

const ErrorRoute: React.FC<Props> = ({ errorMessage }: Props) => {
    const { state } = useLocation<any>()

    return (
        <Container>
            {state?.errorMessage || errorMessage}
        </Container>
    )
}

ErrorRoute.defaultProps = {
    errorMessage: "Ocorreu um Erro!"
}

export default ErrorRoute;