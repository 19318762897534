/* eslint-disable no-param-reassign */
import axios, { AxiosError } from 'axios'
import { asyncLocalStorage } from '../auth/auth'

const axiosInstance = axios.create({
    baseURL: ``,
    timeout: 10000,
})

axiosInstance.interceptors.request.use((config) =>
    asyncLocalStorage
        .getItem(`@services_token`)
        .then((token) => {
            if (token) {
                config.headers.Authorization = `bearer ${token}`
            } else {
                config.headers['Content-Type'] =
                    'application/x-www-form-urlencoded'
            }
        })
        .then(() => {
            asyncLocalStorage.getItem('@services_module').then((module) => {
                asyncLocalStorage
                    .getItem(`@services_service`)
                    .then((service) => {
                        if (service) {
                            config.baseURL = ''
                            let serviceURL = ''

                            if (
                                service === 'ANDROID' ||
                                service === 'IOS' ||
                                service === 'MOBILE'
                            ) {
                                serviceURL = 'mobile'
                            } else if (
                                service === 'DESK' ||
                                service === 'DESKTOP'
                            ) {
                                serviceURL = 'desk'
                            }

                            if (
                                window.location.href.includes('localhost') ||
                                window.location.origin.indexOf('ngrok') > -1
                            ) {
                                const apiEndpoint =
                                    'https://api-[service].simplificaci.com.br/services'
                                config.baseURL = apiEndpoint.replace(
                                    '[service]',
                                    serviceURL
                                )
                            } else if (
                                window.location.href.includes(
                                    'services.simplificaci.com.br'
                                )
                            ) {
                                // Production
                                const apiEndpoint =
                                    'https://api-[service].simplificaci.com.br/services'
                                config.baseURL = apiEndpoint.replace(
                                    '[service]',
                                    serviceURL
                                )
                            } else if (
                                window.location.href.includes(
                                    'services.simplificaci.com.br'
                                )
                            ) {
                                // Beta
                                const apiEndpoint =
                                    'https://api-[service].simplificaci.com.br/services'
                                config.baseURL = apiEndpoint.replace(
                                    '[service]',
                                    serviceURL
                                )
                            }

                            if (service.includes('ngrok')) {
                                config.baseURL = `https://${service}/services` // Teste Prod
                            }

                            if (config?.url === '/security/token') {
                                config.baseURL = config.baseURL.replace(
                                    '/services',
                                    ''
                                )
                            }
                        }
                    })
            })
        })
        .catch((error: AxiosError) => console.log(error))
        .then(() => config)
)

axiosInstance.interceptors.response.use(
    (response) =>
        new Promise((resolve, reject) => {
            resolve(response)
        }),
    (error: AxiosError) => {
        if (!error.response) {
            return new Promise((resolve, reject) => {
                throw new Error('Network Error')
            })
        }

        if (error.response.status === 404 && error.config.baseURL === '') {
            if (window.location.hash) {
                const serviceURL = JSON.parse(error.config.data)
                    .hash.split('#')[1]
                    .split(';')[1]
                    .split('|')[0]

                setTimeout(() => {
                    window.location.href = `/#/${serviceURL}#${
                        JSON.parse(error.config.data).hash
                    }`
                }, 1000)
            }
        }

        return true
    }
)

export const api = axiosInstance
