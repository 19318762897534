import { action } from 'typesafe-actions'
import { BirthworkTypes, IBirthwork, IBirthworkSetup } from './types'

export const loadBirthworkSetup = (data: IBirthworkSetup) =>
    action(BirthworkTypes.LOAD_BIRTHWORK_SETUP, { data })

export const loadBirthworkRequest = () =>
    action(BirthworkTypes.LOAD_BIRTHWORK_REQUEST)

export const loadBirthworkSuccess = (data: IBirthwork[]) =>
    action(BirthworkTypes.LOAD_BIRTHWORK_SUCCESS, { data })

export const loadBirthworkFailure = (error: any) =>
    action(BirthworkTypes.LOAD_BIRTHWORK_FAILURE, error)
