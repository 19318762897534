import { CoworkersIcon, Container, Description } from './styles'
import Coworkers from '../../assets/images/people_shade.png'
import { i18n } from '../../translate/i18n'

const BirthworkNotFound: React.FC = () => (
    <Container>
        <CoworkersIcon src={Coworkers} />
        <Description>{i18n.t('birthwork.notFound')}</Description>
    </Container>
)

export default BirthworkNotFound
