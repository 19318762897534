import { action } from "typesafe-actions"
import { GiftTypes, IGift, IGiftSetup } from "./types"

export const loadGiftSetup = (data: IGiftSetup) => action(GiftTypes.LOAD_GIFT_SETUP, data) 

export const loadGiftRequest = () => action(GiftTypes.LOAD_GIFT_REQUEST)
export const loadGiftSuccess = (data: IGift) => action(GiftTypes.LOAD_GIFT_SUCCESS, { data })
export const loadGiftFailure = (error: string) => action(GiftTypes.LOAD_GIFT_FAILURE, error)

export const receivedGiftRequest = () => action(GiftTypes.RECEIVED_GIFT_REQUEST)
export const receivedGiftSuccess = () => action(GiftTypes.RECEIVED_GIFT_SUCCESS)
export const receivedGiftFailure = (errorMessage: string) => action(GiftTypes.RECEIVED_GIFT_FAILURE, errorMessage)

export const setPIN = (data: string) => action(GiftTypes.SET_PIN, data)