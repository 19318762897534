import React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from '../../../../../store'
import { ITrainingProgress } from '../../../../../store/containers/training/types'
import { Container, Title, Value } from './style'
import { i18n } from '../../../../../translate/i18n'

interface StateProps {
    progress: ITrainingProgress
}

type Props = StateProps

const Progress: React.FC<Props> = (props: Props) => {
    const { progress } = props
    return (
        <Container>
            <Title>{i18n.t('trainings.your-progress')}</Title>
            <Value>
                <span>{progress.actualProgress}</span> /{' '}
                {progress.finalProgress}
            </Value>
        </Container>
    )
}

const mapDispatchToProps = (state: ApplicationState) => ({
    progress: state.trainings.trainingProgress,
})

export default connect(mapDispatchToProps)(Progress)
