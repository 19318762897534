import { action } from "typesafe-actions";
import { DocumentsTypes, IDocumentFolder, IDocumentFile } from "./types";

export const loadDocumentsSetup = () => action(DocumentsTypes.LOAD_DOCUMENTS_SETUP)

export const loadDocumentsRequest = () => action(DocumentsTypes.LOAD_DOCUMENTS_REQUEST)

export const loadFoldersSuccess = (data: IDocumentFolder[]) => action(DocumentsTypes.LOAD_FOLDERS_SUCCESS, { data })
export const loadDocumentsSuccess = (data: IDocumentFile[]) => action(DocumentsTypes.LOAD_DOCUMENTS_SUCCESS, { data })
export const loadSingleDocumentSuccess = () => action(DocumentsTypes.LOAD_FILE_SUCCESS)

export const loadDocumentsFailure = (error: string) => action(DocumentsTypes.LOAD_DOCUMENTS_FAILURE, error)
