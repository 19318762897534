import { IBirthday, IBirthdaySetup } from '../birthdays/types'

export enum BirthworkTypes {
    LOAD_BIRTHWORK_SETUP = '@birthwork/LOAD_BIRTHWORK_SETUP',

    LOAD_BIRTHWORK_REQUEST = '@birthwork/LOAD_BIRTHWORK_REQUEST',
    LOAD_BIRTHWORK_SUCCESS = '@birthwork/LOAD_BIRTHWORK_SUCCESS',
    LOAD_BIRTHWORK_FAILURE = '@birthwork/LOAD_BIRTHWORK_FAILURE',
}

export interface IBirthworkSetup extends IBirthdaySetup {}

export interface IBirthwork extends IBirthday {
    anoContratacao: string
}

export interface IBirthworkItemProps {
    birthday: IBirthwork
    activeTab: number
}

export interface BirthworkState {
    readonly setup: IBirthworkSetup
    readonly birthwork: IBirthwork[]
    readonly loading: boolean
    readonly error: boolean
    readonly errorMessage: string
}
