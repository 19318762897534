/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Redirect, useHistory, useParams } from 'react-router'
import Skeleton from 'react-loading-skeleton'
import Switch from "react-switch"
import { useTheme } from 'styled-components'
import { ApplicationState } from '../../../store'
import { ITerm } from '../../../store/containers/terms/types'
import TermThunk from '../../../store/containers/terms/thunk'

import Terms from './components/Terms/index'
import Signature from "./components/Signature"

import { Container, Divider, Header, Image, HeaderContainer, Title, Department, Button, Confirmation, ConfirmationSwitch } from './style'
import defaultImage from "../../../assets/images/default.jpg"
import { useConfirmation } from '../../../components/Modal/Confirmation/confirmation-service'

interface IParams {
    id: string
}
interface StateProps {
    activeTerm: ITerm
    loading: boolean,
    error: boolean,
    errorMessage: string
}
interface DispatchProps {
    loadSingleTerm(id: number): void
    completeTerm(id: number, signature?: string): void
    loadTermRequest(): void
}

type Props = StateProps & DispatchProps

const TermDetail: React.FC<Props> = (props: Props) => {
    const confirm = useConfirmation()
    const history = useHistory()

    const { id } = useParams<IParams>()
    const { colors } = useTheme()

    const [isSigned, setIsSigned] = useState<boolean>(false)
    const [signData, setSignData] = useState<string>('')
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        const { loadSingleTerm, loadTermRequest } = props

        loadTermRequest()
        loadSingleTerm(Number(id))
    }, [id])

    function imageError(ev: SyntheticEvent<HTMLImageElement, Event>) {
        // eslint-disable-next-line no-param-reassign
        ev.currentTarget.src = defaultImage
    }

    const handleComplete = useCallback((signature: string) => {
        const { completeTerm } = props
        completeTerm(Number(id), signature)

        confirm({
            variant: 'info',
            description: "Termo aceito com sucesso!"
        })
            .then(() => history.push("/terms"))
    }, [])

    function isDisabled(term: ITerm) {
        if (loading) {
            return true
        }

        if (term.requerAssinatura && !isSigned) {
            return true
        }

        if (!term.requerAssinatura && !checked) {
            return true
        }

        return false
    }

    function showSignature(term: ITerm) {

        if (loading) return (<Skeleton width={280} height={28} />)

        // eslint-disable-next-line no-extra-boolean-cast
        if (Boolean(term.requerAssinatura)) {
            return (
                <>
                    <p> Assine abaixo para concordar com os termos. </p>
                </>
            )
        }

        return (
            <>
                <p> Estou ciente dos termos acima. </p>
                <Switch checked={checked} onChange={setChecked} onColor={colors.primary} />
            </>
        )

    }

    const { activeTerm, loading, error, errorMessage } = props

    return (
        <>
            {!error ?
                <>
                    <Divider />
                    <Container>
                        <Header>
                            {!loading ?
                                <Image onError={imageError} src={activeTerm.imagem} />
                                :
                                <Skeleton width={81} height={81} />
                            }
                            <HeaderContainer>
                                <Title>{!loading ? activeTerm.titulo : <Skeleton width={150} height={29} />}</Title>
                                <Department>{!loading ? activeTerm.subtitulo : <Skeleton width={90} height={18} />}</Department>
                            </HeaderContainer>
                        </Header>
                        {!loading ?
                            <Terms text={activeTerm.conteudo} />
                            :
                            <div style={{ margin: '20px 0' }}>
                                <Skeleton count={25} />
                            </div>
                        }
                        {
                            activeTerm?.status === 0 &&
                            <>
                                <Confirmation>
                                    <ConfirmationSwitch flex={Boolean(activeTerm.requerAssinatura)}>
                                        {showSignature(activeTerm)}
                                    </ConfirmationSwitch>
                                    {
                                        activeTerm.requerAssinatura &&
                                        <Signature setSignData={setSignData} setIsSigned={setIsSigned} />
                                    }
                                    <Button onClick={() => handleComplete(signData)} disabled={isDisabled(activeTerm)}>Concluir</Button>
                                </Confirmation>
                            </>
                        }
                    </Container>
                </>
                :
                <Redirect to={{ pathname: '/error', state: { errorMessage } }} />
            }
        </>
    )
}

const mapStatesToProps = (state: ApplicationState) => ({
    loading: state.terms.loading,
    error: state.terms.error,
    activeTerm: state.terms.activeTerm,
    errorMessage: state.terms.errorMessage
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadTermRequest: () => dispatch({ type: "@terms/LOAD_TERM_REQUEST" }),
    loadSingleTerm: (id: number) => dispatch(TermThunk.loadSingleTerm(id) as any),
    completeTerm: (id: number, signature?: string) => dispatch(TermThunk.completeTerm(id, signature) as any)
})

export default connect(mapStatesToProps, mapDispatchToProps)(TermDetail)