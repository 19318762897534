import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    padding: 20px 25px;
`;

export const Dot = styled.div<{ size: number }>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;

    background-color: ${props => props.theme.colors.primary};
    border-radius: 50%;

    margin-bottom: 5px;
`
export const Headline = styled.h2`
    text-align: center;
    font-size: 20px;
    font-weight: 700;
`

export const Text = styled.p`
    text-align: center;
    font-size: 11px;
    font-weight: 400;
`