import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

import {
    Dialog,
    DialogIcon,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    DialogTitle,
    DialogCloseButton,
    Backdrop,
} from './style'

export interface ConfirmationOptions {
    catchOnCancel?: boolean
    variant?: 'danger' | 'info'
    description?: string
    icon?: string
    title?: string
}

interface ConfirmationDialogProps extends ConfirmationOptions {
    open: boolean
    onSubmit: () => void
    onClose: () => void
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
    open,
    variant,
    description,
    icon,
    title,
    onSubmit,
    onClose,
}: ConfirmationDialogProps) => {
    const onKeyboard = (event: KeyboardEvent) => {
        if (event.keyCode === 27 && open) {
            onClose()
        }
    }

    useEffect(() => {
        document.body.style.overflow = open ? 'hidden' : 'unset'
        document.addEventListener('keydown', onKeyboard, false)
        return () => {
            document.removeEventListener('keydown', onKeyboard, false)
        }
    }, [open])

    const modal = (
        <>
            <Backdrop onClick={onClose} />
            <Dialog
                arial-modal
                aria-labelledby={title}
                tabIndex={-1}
                role='dialog'>
                <DialogCloseButton onClick={onClose} />
                <DialogIcon src={icon} />
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{description}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    {variant === 'danger' && (
                        <>
                            <Button color='primary' onClick={onSubmit}>
                                Confirmar
                            </Button>
                            {/* <Button color="primary" onClick={onClose} autoFocus>
                        Cancelar
                    </Button> */}
                        </>
                    )}

                    {variant === 'info' && (
                        <Button color='primary' onClick={onSubmit}>
                            Confirmar
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )

    return open ? ReactDOM.createPortal(modal, document.body) : null
}
