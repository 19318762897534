import React, { SyntheticEvent, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useTheme } from 'styled-components';
import { useModal } from '../../../hooks/useModal';
import { useConfirmation } from '../../../components/Modal/Confirmation/confirmation-service';
import { IClassified } from '../../../store/containers/classified/types';
import { ApplicationState } from '../../../store';
import ClassifiedThunk from '../../../store/containers/classified/thunk';
import MyAnnouncementsStatus from '../components/myAnnouncementsStatus';

import { Modal } from '../../../components/Modal';
import { ContainerList, Card, CardContainer, CardImage, CardCategory, CardTitle, CardValor, AnnouncementOptions, AnnouncementEdit, CreateButton, AnnouncementStatus, CardWrapper, EditOptions } from './style';
import Edit from "../../../assets/images/gear.svg"
import defaultImage from '../../../assets/images/default.jpg'

interface StateProps {
    myAnnouncements: IClassified[]
}

interface DispatchProps {
    removeAnnouncement(announcementID: number): void
    sellProduct(announcementID: number): void
}

type Props = StateProps & DispatchProps

const MyAnnouncements: React.FC<Props> = ({ myAnnouncements, removeAnnouncement, sellProduct }: Props) => {
    const { isShown, toggle } = useModal();
    const [announcementModal, setAnnouncementModal] = useState<IClassified>({} as IClassified)

    const confirm = useConfirmation()

    const removeAnnouncementSubmit = useCallback((announcementID: number) =>
        confirm({
            variant: "danger",
            catchOnCancel: false,
            description: "Tem certeza de que deseja EXCLUIR este anúncio?"
        })
            .then(() => {
                removeAnnouncement(announcementID)
            })
            .catch(() => toggle()), []
    )

    const sellProductSubmit = useCallback((announcementID: number) =>
        confirm({
            variant: "danger",
            catchOnCancel: true,
            description: "Deseja marcar seu produto como vendido?"
        })
            .then(() => {
                sellProduct(announcementID)
            })
            .catch(() => toggle()), [])

    const { colors } = useTheme()

    function imageError(ev: SyntheticEvent<HTMLImageElement, Event>) {
        // eslint-disable-next-line no-param-reassign
        ev.currentTarget.src = defaultImage
    }

    return (
        <>
            <ContainerList>
                {myAnnouncements.map((announcement: IClassified) => (
                    !announcement.Vendido &&
                    <Card key={announcement.CodigoClassificadoItem}>
                        <CardContainer>
                            <CardWrapper>
                                <CardCategory>{announcement.Categoria}</CardCategory>
                                <CardTitle>{announcement.Titulo}</CardTitle>
                            </CardWrapper>

                            <CardWrapper>
                                <CardValor>R$ {announcement.Valor.toFixed(2).toString().replace(".", ",")}</CardValor>

                                <AnnouncementOptions>
                                    <AnnouncementEdit>
                                        <img src={Edit} alt="" />
                                        <button type="button" onClick={() => { toggle(); setAnnouncementModal(announcement) }}>Editar</button>
                                    </AnnouncementEdit>
                                </AnnouncementOptions>
                            </CardWrapper>
                            <AnnouncementStatus>
                                <MyAnnouncementsStatus status={
                                    announcement.Vendido ?
                                        0 :
                                        announcement.StatusAprovacaoCodigo
                                } statusName={
                                    announcement.Vendido ?
                                        'Vendido' :
                                        announcement.StatusAprovacao
                                } />
                            </AnnouncementStatus>
                        </CardContainer>

                        <CardImage>
                            <img onError={imageError} src={announcement.Imagem1} alt="" />
                        </CardImage>
                    </Card>
                ))}

                <Modal isShown={isShown} hide={toggle} headerText={announcementModal.Titulo} modalContent={
                    <EditOptions>
                        {
                            announcementModal.TipoAnuncio === 0 &&
                            <button type="button" onClick={() => { toggle(); sellProductSubmit(announcementModal.CodigoClassificadoItem) }}>Marcar como vendido</button>
                        }
                        <Link onClick={toggle} to={{ pathname: `classified/create/${announcementModal.CodigoClassificadoItem}` }}>Editar</Link>
                        <button style={{ color: colors.danger }} type="button" onClick={() => { toggle(); removeAnnouncementSubmit(announcementModal.CodigoClassificadoItem) }}>Excluir</button>
                    </EditOptions>
                } />
            </ContainerList>
            <CreateButton>
                <Link to={{ pathname: 'classified/create' }}>Criar novo anúncio</Link>
            </CreateButton>
        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    removeAnnouncement: (announcementID: number) => dispatch(ClassifiedThunk.removeAnnouncement(announcementID) as any),
    sellProduct: (announcementID: number) => dispatch(ClassifiedThunk.sellProduct(announcementID) as any)
})

const mapStateToProps = (state: ApplicationState) => ({
    myAnnouncements: state.classifieds.myAnnouncements,
})

export default connect(mapStateToProps, mapDispatchToProps)(MyAnnouncements);