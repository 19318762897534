import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import Breadcrumb from '../../../components/Breadcrumb'
import Loader from '../../../components/Loader'
import { ApplicationState } from '../../../store'
import { setSettingsState } from '../../../store/containers/settings/actions'
import SettingsThunk from '../../../store/containers/settings/thunk'
import {
    ISettings,
    ISettingsOptions,
    ISettingsSetup,
    TSettingsField,
} from '../../../store/containers/settings/types'
import Accessibility from '../Accessibility'
import Notifications from '../Notifications'
import Profile from '../Profile'
import Security from '../Security'
import { Container, SettingsContainer } from './styles'
import { ImageFile } from '../../../components/Forms/FileGroup'

const useDebouncedValue = (inputValue: any, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(inputValue);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(inputValue);
      }, delay);
  
      return () => {
        clearTimeout(handler);
      };
    }, [inputValue, delay]);
  
    return debouncedValue;
  };

interface StateProps {
    loading: boolean
    error: boolean
    errorMessage: string
    settings: ISettings
    setup: ISettingsSetup
    options: ISettingsOptions
}

interface DispatchProps {
    loadSettings: () => void
    updateSettings: (settings: ISettings) => void
    setSettingsState: (field: TSettingsField, value: any) => void
}

type Props = StateProps & DispatchProps

const SettingsScreen: React.FC<Props> = ({
    loading,
    error,
    errorMessage,
    settings,
    setup,
    options,
    loadSettings,
    updateSettings,
    setSettingsState,
}) => {
    const [image, setImage] = React.useState<string>('')
    const {
        nomeSocial,
        genero,
        pronome,
        modoNoturno,
        filtroCor,
        contraste,
        tamanhoFonte,
        leituraLibras,
        leituraVoz,
        usarBiometria,
        aniversarioVida,
        isLoaded,
    } = settings

    const {
        showAcessibilidade = true,
        showSeguranca,
        showNotificacoes,
        settingsAvailable,
    } = setup

    
    const debouncedNomeSocial:string = useDebouncedValue(nomeSocial, 1500);

    useEffect(() => {
        loadSettings()
    }, [setup])

    useEffect(() => {
        const settings = {
            nomeSocial: debouncedNomeSocial.trim(),
            genero,
            pronome,
            modoNoturno,
            filtroCor,
            contraste,
            tamanhoFonte,
            leituraLibras,
            leituraVoz,
            usarBiometria,
            aniversarioVida,
        }
        isLoaded && updateSettings(settings)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        debouncedNomeSocial,
        genero,
        pronome,
        modoNoturno,
        filtroCor,
        contraste,
        tamanhoFonte,
        leituraLibras,
        leituraVoz,
        usarBiometria,
        aniversarioVida,
    ])

    return (
        <Loader loading={!isLoaded} error={error} errorMessage={errorMessage}>
            <Container>
                {setup.TituloModulo && (
                    <Breadcrumb show={true} TituloModulo={setup.TituloModulo} />
                )}
                <SettingsContainer>
                    <Profile
                        nomeSocial={nomeSocial}
                        genero={genero}
                        pronome={pronome}
                        options={options}
                        setSettingsState={setSettingsState}
                        settingsAvailable={settingsAvailable}
                    />
                    {showAcessibilidade && (
                        <Accessibility
                            modoNoturno={modoNoturno}
                            filtroCor={filtroCor}
                            contraste={contraste}
                            tamanhoFonte={tamanhoFonte}
                            libras={leituraLibras}
                            leituraVoz={leituraVoz}
                            setSettingsState={setSettingsState}
                            settingsAvailable={settingsAvailable}
                        />
                    )}
                    {showSeguranca && (
                        <Security
                            usarBiometria={usarBiometria}
                            setSettingsState={setSettingsState}
                            settingsAvailable={settingsAvailable}
                        />
                    )}
                    {showNotificacoes && (
                        <Notifications
                            aniversarioVida={aniversarioVida}
                            setSettingsState={setSettingsState}
                            settingsAvailable={settingsAvailable}
                        />
                    )}

                    {/* <ImageFile
                        imageIndex={0}
                        image={image}
                        setImage={setImage}
                    /> */}

                </SettingsContainer>
            </Container>
        </Loader>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.settings.loading,
    error: state.settings.error,
    errorMessage: state.settings.errorMessage,
    settings: state.settings.settings,
    setup: state.settings.setup,
    options: state.settings.options,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadSettings: () => dispatch(SettingsThunk.loadSettings() as any),

    updateSettings: (settings: ISettings) =>
        dispatch(SettingsThunk.updateSettings(settings) as any),

    setSettingsState: (field: TSettingsField, value: any) =>
        dispatch(setSettingsState(field, value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen)
