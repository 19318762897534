/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types'
import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { asyncLocalStorage } from '../../../auth/auth'
import { api } from '../../../services/api'
import simplifica from '../../../styles/themes/simplifica'
import * as DocumentsActions from './actions'
import { IDocumentFile, IDocumentFolder } from './types'

const DocumentsThunk = {
    loadSetup: (hash: string) => (dispatch: Dispatch) => {
        dispatch(DocumentsActions.loadDocumentsRequest())
        api.post('/documentosrh/setup', { hash })
            .then((response: AxiosResponse) => {
                if (response.data?.state) {
                    DocumentsThunk.loadTheme(response.data.data)
                    asyncLocalStorage
                        .setItem(
                            '@services_header',
                            JSON.parse(response.data.data)[0].EsconderHeader
                        )
                        .then(() =>
                            dispatch(DocumentsActions.loadDocumentsSetup())
                        )
                } else {
                    Sentry.captureException('SETUP - Erro no Carregamento')
                    dispatch(
                        DocumentsActions.loadDocumentsFailure(
                            'SETUP - Erro no Carregamento'
                        )
                    )
                }
            })
            .catch((error: AxiosError) => {
                Sentry.captureException(error)
                dispatch(
                    DocumentsActions.loadDocumentsFailure(
                        `SETUP - ${error.message}`
                    )
                )
            })
    },
    // TODO: Refactor to abstract the theme loading
    loadTheme: (data: any) => {
        const { TituloModulo, CorPrimaria, CorSecundaria } = JSON.parse(data)[0]
        asyncLocalStorage
            .setItem('@services_page_title', TituloModulo)
            .then(() => {
                simplifica.colors.primary = CorPrimaria
                simplifica.colors.secondary = CorSecundaria

                simplifica.trainingButtons[0].color = CorPrimaria
                simplifica.trainingButtons[1].color = CorPrimaria

                simplifica.classifiedCategories.tipoUso[0].background =
                    CorSecundaria
            })
    },
    loadFolders: () => (dispatch: Dispatch) => {
        api.get('/documentosrh/list')
            .then((response: AxiosResponse) => {
                if (response.data?.state) {
                    const folders: IDocumentFolder[] = JSON.parse(
                        response.data.data
                    )

                    if (folders.length > 0) {
                        folders.forEach((folder: IDocumentFolder) => {
                            folder.brand = folder.icone.split(
                                ' '
                            )[0] as IconPrefix
                            folder.icon = folder.icone.split('-')[1] as IconName
                        })
                        dispatch(DocumentsActions.loadFoldersSuccess(folders))
                    }
                } else {
                    dispatch(
                        DocumentsActions.loadDocumentsFailure(
                            'Não foram encontradas pastas.'
                        )
                    )
                }
            })
            .catch((error: AxiosError) => {
                Sentry.captureException(error)
                dispatch(
                    DocumentsActions.loadDocumentsFailure(
                        `folder - ${error.message}`
                    )
                )
            })
    },
    loadFiles: (folderID: number) => (dispatch: Dispatch) => {
        dispatch(DocumentsActions.loadDocumentsRequest())
        api.get(`/documentosrh/list/${folderID}`)
            .then((response: AxiosResponse) => {
                if (
                    response.data?.state &&
                    JSON.parse(response.data.data).length > 0
                ) {
                    const files: IDocumentFile[] = JSON.parse(
                        response.data.data
                    )
                    dispatch(DocumentsActions.loadDocumentsSuccess(files))
                } else {
                    dispatch(
                        DocumentsActions.loadDocumentsFailure(
                            'Não foram encontrados documentos.'
                        )
                    )
                }
            })
            .catch((error: AxiosError) => {
                Sentry.captureException(error)
                dispatch(
                    DocumentsActions.loadDocumentsFailure(
                        `documents - ${error.message}`
                    )
                )
            })
    },

    showFile: (folderID: number, fileID: number) => (dispatch: Dispatch) => {
        api.get(`/documentosrh/show/${folderID}/${fileID}`)
    },
}

export default DocumentsThunk
