// eslint-disable-next-line no-shadow
export enum AppTypes {
    LOAD_UPLOAD_REQUEST = "@app/LOAD_UPLOAD_REQUEST",
    LOAD_UPLOAD_SUCCESS = "@app/LOAD_UPLOAD_SUCCESS",
    LOAD_UPLOAD_FAILURE = "@app/LOAD_UPLOAD_FAILURE",

    LOAD_APP_TOKEN_REQUEST = "@app/LOAD_APP_TOKEN_REQUEST",
    LOAD_APP_TOKEN_SUCCESS = "@app/LOAD_APP_TOKEN_SUCCESS",
    LOAD_APP_TOKEN_FAILURE = "@app/LOAD_APP_TOKEN_FAILURE",

    SET_APP_THEME = "@app/SET_APP_THEME",

    SET_APP_SERVICE = "@app/SET_APP_SERVICE",

    LOAD_APP_TOKEN_SUCCESS_NEW = "@app/LOAD_APP_TOKEN_SUCCESS_NEW",
}

export interface ITheme {
    CorPrimaria: string,
    CorSecundaria: string
}

export interface AppState {
    readonly uploadLoading: boolean;
    readonly uploadError: boolean;
    readonly loading: boolean;
    readonly error: boolean;
    readonly errormessage: string;
    readonly service: string;
    readonly theme: ITheme;
}