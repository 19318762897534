import { IconName, IconPrefix } from "@fortawesome/fontawesome-common-types"
/* eslint-disable camelcase */
/**
 * Action Types
 */
// eslint-disable-next-line no-shadow
export enum DocumentsTypes {
    LOAD_DOCUMENTS_REQUEST = "@documents/LOAD_DOCUMENTS_REQUEST",

    LOAD_FOLDERS_SUCCESS = "@documents/LOAD_FOLDERS_SUCCESS",
    LOAD_DOCUMENTS_SUCCESS = "@documents/LOAD_DOCUMENTS_SUCCESS",
    LOAD_FILE_SUCCESS = "@documents/LOAD_FILE_SUCCESS",

    LOAD_DOCUMENTS_FAILURE = "@documents/LOAD_DOCUMENTS_FAILURE",

    LOAD_SINGLE_DOCUMENT_SUCCESS = "@documents/LOAD_SINGLE_DOCUMENT_SUCCESS",
    LOAD_DOCUMENTS_SETUP = "@documents/LOAD_DOCUMENTS_SETUP"
}

/** 
 * Data Types
*/

export interface IDocumentFile {
    darCodigo: number,
    referencia: string,
    action: string,
    url: string
}

export interface IDocumentFolder {
    dtpCodigo: number,
    titulo: string,
    subtitulo: string,
    icone: string,
    brand: IconPrefix,
    icon: IconName,
}

/**
 * State Types
 */

export interface DocumentsState {
    readonly documentFiles: IDocumentFile[],
    readonly documentFolders: IDocumentFolder[],
    readonly loading: boolean,
    readonly error: boolean
    readonly errorMessage: string
}