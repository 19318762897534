import React from 'react';
import { postMessageToRN } from '../../../../../utils'
import { Embed } from './style';

type Props = {
    text: string
}

const TrainingContent: React.FC<Props> = ({ text }: Props) => {
    const openURL = (event: any) => {
        const url = event.target.href || event.target.dataset.dnvideo || ''

        if (url) {
            postMessageToRN(url)
        }
    }

    const handleClicks = (event: any) => {
        if (event && event.target && event.target.tagName.toLowerCase() === 'a') {
            openURL(event)
        } else if (event && event.target && event.target.tagName.toLowerCase() === 'img') {
            openURL(event)
        }

        event.preventDefault()
    }

    return <Embed onClick={handleClicks} html={text} />
}

export default TrainingContent;