import { Reducer } from "redux";
import { ITraining, ITrainingProgress, TrainingState, TrainingTypes } from "./types";

const INITIAL_STATE: TrainingState = {
    trainings: [],
    activeTraining: {} as ITraining,
    trainingProgress: {} as ITrainingProgress,
    error: false,
    loading: true,
    errorMessage: ""
}

const reducer: Reducer<TrainingState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TrainingTypes.LOAD_TRAINING_REQUEST:
            return { ...state, loading: true }
        case TrainingTypes.LOAD_TRAINING_SETUP:
            return { ...state, loading: false, error: false }
        case TrainingTypes.LOAD_TRAINING_SUCCESS:
            return { ...state, loading: false, error: false, trainings: action.payload.data, errorMessage: "" }
        case TrainingTypes.LOAD_TRAINING_FAILURE:
            return { ...state, error: true, loading: false, errorMessage: action.payload }
        case TrainingTypes.LOAD_TRAINING_PROGRESS:
            return { ...state, trainingProgress: action.payload.progress }
        case TrainingTypes.LOAD_SINGLE_TRAINING_SUCCESS: {
            return { ...state, error: false, loading: false, activeTraining: action.payload.data }
        }
        default:
            return state
    }
}

export default reducer