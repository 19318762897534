import styled from 'styled-components'
import { TBirthdayType } from '../../../../../store/containers/birthdays/types'

export const ListContainer = styled.div<{ cardType: TBirthdayType }>`
    display: ${({ cardType }) => (cardType === 'birthwork' ? 'flex' : 'grid')};
    padding-top: 16px;
    ${({ cardType }) =>
        cardType === 'birthwork'
            ? `
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
            `
            : `
              grid-template-columns: repeat(2, 50%);
            `}
`

export const LoaderContainer = styled.div`
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${(props) => props.theme.colors.background};
`
