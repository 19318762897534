// eslint-disable-next-line import/no-anonymous-default-export
export default {
    title: 'simplifica',

    colors: {
        primary: '#8C2984',
        secondary: '#078EC8',
        success: '#2DAE00',
        alert: '#ffc107',
        danger: '#B90000',
        info: '#17a2b8',

        background: '#FFF',
        text: '#333',
        textLight: '#525252',
        textMuted: '#999',
    },

    card: {
        background: '#FFF',
        text: '#333',
        borderRadius: '10px',
    },

    trainingButtons: [
        { key: 0, color: '#8C2984' },
        { key: 1, color: '#000000' },
    ],

    trainingCategories: [
        { key: 0, background: '', color: '' },
        { key: 1, background: '#B90000', color: 'white' },
        { key: 2, background: '#2DAE00', color: 'white' },
    ],

    classifiedCategories: {
        tipoUso: [
            { key: 1, background: '', color: '#fff' }, // NOVO
            { key: 2, background: '', color: '' }, // USADO
        ],
        aceitaTroca: { background: '#606060', color: '#fff' },
    },
}
