import { Reducer } from "redux";
import { GiftState, GiftTypes, IGift, IGiftSetup } from "./types";

const INITIAL_STATE: GiftState = {
    gifts: {} as IGift,
    giftSetup: {} as IGiftSetup,
    loading: false,
    error: false,
    errorMessage: "",
    pin: "",
    receivedLoading: false,
    receivedError: false,
    receivedErrorMessage: ""
}

const reducer: Reducer<GiftState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GiftTypes.LOAD_GIFT_SETUP:
            return { ...state, loading: false, error: false, giftSetup: action.payload }
        case GiftTypes.LOAD_GIFT_REQUEST:
            return { ...state, loading: true, error: false }
        case GiftTypes.LOAD_GIFT_SUCCESS:
            return { ...state, loading: false, error: false, gifts: action.payload.data }
        case GiftTypes.LOAD_GIFT_FAILURE:
            return { ...state, loading: false, error: true, errorMessage: action.payload }
        case GiftTypes.RECEIVED_GIFT_REQUEST:
            return { ...state, receivedLoading: true, receivedError: false }
        case GiftTypes.RECEIVED_GIFT_SUCCESS:
            return { ...state, receivedLoading: false, receivedError: false, pin: "", gifts: {} as IGift }
        case GiftTypes.RECEIVED_GIFT_FAILURE:
            return { ...state, receivedLoading: false, receivedError: true, receivedErrorMessage: action.payload }
        case GiftTypes.SET_PIN:
            return { ...state, loading: false, erro: false, pin: action.payload }
        default:
            return state
    }
}

export default reducer