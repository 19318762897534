import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 32px;
    flex-wrap: wrap;
    gap: 16px;
    min-height: 100vh;
`

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-radius: 8px;
    background-color: #f0f0f0;
    gap: 8px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        transform: scale(1.05);
    }
`

export const Title = styled.a`
    font-size: 24px;
    font-weight: bold;
    color: #666;
    text-decoration: none;
`
